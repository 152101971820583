import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsEventsService} from '../shared/services/google-analytics-events.service';
import {ErrorService} from '../shared/services/error-service';
import {PageStyle} from '../models/style/style.model';
import {FontsAndColorsService} from '../shared/services/fonts-and-colors.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
  styles = new PageStyle();

  public error: any;

  constructor(public fontsAndColorsService: FontsAndColorsService, private errorService: ErrorService, private googleAnalytics: GoogleAnalyticsEventsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();

  }

  ngOnInit() {
    this.error = this.errorService.getError();
    //
    if (this.error && this.error.action) {
      this.googleAnalytics.emitEvent('error', this.error.action, this.error.message);
    }
  }

}
