import { WebNewsModel } from '../../../routes/news/news-model';
import { WebManageMediaModel } from '../../../download/download-model';

export class SearchModel {
  public subClients: ClientLookupModel[];
  public mainCategories: BaseLookupModel[];
  public customCategories: CustomCategoryLookupModel[];
  public searchModel: WebSearchModel;
  public hasCustomCategories: boolean;
  public hasMainCategories: boolean;


  constructor() {
    this.searchModel = new WebSearchModel();
    this.subClients = [];
  }
}

export class ClientLookupModel {
  public parentId: number;
  public id: number;
  public label: string;

  //added new properties
  public checked: boolean;
}

export class BaseLookupModel {
  public id: number;
  public label: string;

  //added new properties
  public checked: boolean;
}

export class CustomCategoryLookupModel {
  public parentId: number;
  public mainCategoryId: number;
  public clientId: number;
  public id: number;
  public label: string;

  //added new properties
  public marked: boolean;
}

export class WebSearchModel {
  public stories: boolean;
  public media: boolean;
  public images: boolean;
  public video: boolean;
  public document: boolean;
  public audio: boolean;
  public dateFrom: string;
  public dateTo: string;
  public searchText: string;
  public mainCategoryIds: number[];
  public customCategoryIds: number[];
  public subClientIds: number[];
  public page: number;
  public pageSize: number;
  public languageId: number;

  constructor() {
    this.stories = true;
    this.media = true;
    this.images = true;
    this.video = false;
    this.document = false;
    this.audio = false;
    this.dateFrom = null;
    this.dateTo = null;
    this.searchText = '';
    this.customCategoryIds = [];
    this.mainCategoryIds = [];
    this.subClientIds = [];
  }
}

export class SearchResultModel {
  public stories: WebNewsModel[];
  public media: WebManageMediaModel[];
  public mustRegisterToAccess: boolean;
  public mustRegisterToDownload: boolean;
}
