import {DownloadMediaModel, WebManageMediaModel} from '../../../download/download-model';

export class PopupModel {
  public media: WebManageMediaModel;
  public downloadLinks: DownloadMediaModel[];
  public alsoIn: boolean;
  public alsoInTitle: string;
  public alsoInShortDescription: string;
  public socialSharing: string[];
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public anonymousShare: boolean;
  public alsoInStoryLink?: string;
  public shareUrl?: string;
  public shareTitle?: string;
  public shareShortDescription?: string;
}
