<div class="story-search-container">
  <div class="story-search-icon">
    <i
      class="fas fa-search"
      [ngStyle]="{
        color: styles.style ? styles.style.mainIdentifyingColor : 'black'
      }"
    ></i>
  </div>
  <input
    id="story-search"
    type="text"
    class="{{ cssClass }}"
    [placeholder]="placeholder"
    [(ngModel)]="inputValue"
  />
</div>
