import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailModel} from '../login-model';
import {TokenManagerService} from '../../shared/services/token-manager.service';
import {CustomValidators} from 'ng2-validation';
import {PageStyle} from '../../models/style/style.model';
import {FontsAndColorsService} from '../../shared/services/fonts-and-colors.service';
import {Language} from '../../models/language/language.model';
import {LanguageService} from '../../shared/services/language.service';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.less']
})
export class EmailPopupComponent implements OnInit {

  @Output() emailPopupChange = new EventEmitter<boolean>();
  @Output() resetPasswordChange = new EventEmitter<boolean>();
  @Output() loginViewChange = new EventEmitter<boolean>();
  @Input() emailPopup: boolean;
  @Input() resetPassword: boolean;
  @Input() loginView: boolean;
  valForm: FormGroup;
  data = new EmailModel();
  notSend = true;
  checkEmail = false;
  maiLSent: boolean;
  styles = new PageStyle();
  language: Language;

  constructor(private fb: FormBuilder, private languageService: LanguageService, private authLoginService: TokenManagerService,public fontsAndColorsService: FontsAndColorsService) {
    this.valForm = this.fb.group({
      'emailforpassword': [null, Validators.compose([Validators.required, CustomValidators.email])]
    });
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );
  }


  ngOnInit() {
  }

  close() {
    this.emailPopupChange.emit(false);
  }

  sendEmail(e, data) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.maiLSent = true;
      this.authLoginService.ForgotPassword(data.email)
        .subscribe(
          (response) => {
            this.notSend = false;
            if(response){
            }
          },
          (error) => {
            this.checkEmail = true;
            this.maiLSent = false;
            //
          }
        );
      /* this.emailPopupChange.emit(false);
       this.resetPasswordChange.emit(true);
       this.loginViewChange.emit(false);*/
    }

  }
}
