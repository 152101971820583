import { Injectable } from "@angular/core";
import { UserCentricsService } from "./user-centrics.service";
declare let gtag: Function;
@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsEventsService {
  trackingId: string;
  constructor(private userCentrics: UserCentricsService) {}

  public initGoogleAnalytics(trackingID: string) {
    this.trackingId = trackingID;
    this.userCentrics.updateGA4Id(this.trackingId);
  }

  private getURLParameter(name) {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.search
        ) || [null, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  }

  public emitEvent(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (this.userCentrics.isAnalyticsConsented())
      gtag("event", eventName, {
        event_label: eventLabel,
        event_category: eventCategory,
        event_action: eventAction,
        non_interaction: false,
        value: eventValue,
        uaId: this.trackingId,
      });
  }

  public emitNonInteracionalEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (this.userCentrics.isAnalyticsConsented())
      gtag("event", eventAction, {
        event_label: eventLabel,
        event_category: eventCategory,
        non_interaction: true,
        value: eventValue,
        uaId: this.trackingId,
      });
  }

  public emitArticleEvent(webLocation: string, storyId: number) {
    var utm_source = this.getURLParameter("utm_source");
    if (!utm_source) utm_source = webLocation;
    if (this.userCentrics.isAnalyticsConsented())
      this.emitNonInteracionalEvent(
        "Articles",
        "Article page display",
        utm_source,
        storyId
      );
  }

  public emitPageView(currentUrl: string) {
    if (this.userCentrics.isAnalyticsConsented())
      this.emitEvent("pageview", "pageview", "pageview", currentUrl);
  }

  public emitReadMoreEvent(
    clickLocation: string,
    articleTitle: string,
    url: string
  ) {
    if (this.userCentrics.isAnalyticsConsented())
      gtag("event", "READ MORE CTA Click", {
        event_label: clickLocation,
        event_category: "ReadMore",
        non_interaction: false,
        dimension1: articleTitle,
        dimension2: url,
        uaId: this.trackingId,
      });
  }
}
