<section class="terms flex-center">
  <div class="content-wrapper">
    <div class="terms-content">
      <div class="terms-bar flex-center">
        <h1 class="section-headline">{{ language.termsAndConditions }}</h1>
      </div>
      <div class="terms-wrapper flex">
        <p [innerHTML]="termsResponseModel.terms"></p>

      </div>
      <div class="terms-bar flex-center">
        <h1 class="section-headline">{{ language.privacyPolicy }}</h1>
      </div>
      <div class="terms-wrapper flex">
        <p [innerHTML]="termsResponseModel.condition"></p>
      </div>
    </div>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>