import { UrlsModel } from "../../models/links/urls.model";

export class ContactsModel {
  public contactsH1Text: string;
  public contactsH2Text: string;
  public contactsH3Text: string;
  public contactsText: string;
  public contactsFinalText: string;
  public contactsQuoteText: string;
  public contactsSignature: string;
  public contactsBeforeStoreButtonsText: string;
  public contactsAfterStoreButtonsText: string;
  public contactsHeroImageUrl: string;
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public contactsLinks: UrlsModel[];
  public ourTeam: TeamModel[];
  public rotary: RotaryModel[];

  constructor() {
    this.contactsH1Text = '';
    this.contactsH2Text = '';
    this.contactsH3Text = '';
    this.contactsQuoteText = '';
    this.contactsSignature = '';
    this.contactsText = '';
    this.contactsHeroImageUrl = '';
    this.contactsAfterStoreButtonsText = '';
    this.contactsBeforeStoreButtonsText = '';
  }
}
export class TeamModel {
  public name: string;
  public email: string;
  public description: string;
  public position: string;
  public thumbUrl: string;
}
export class RotaryModel {
  public day: string;
  public date: string;
  public name: string;
}
