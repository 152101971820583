<section class="news-group" [ngClass]="{ 'mirrored': mirrored }">
  <div class="big-container">
    <app-story-preview *ngIf="group.length > 0" [story]="group[0]" [config]="config"
      [customClasses]="'two-thirds'"></app-story-preview>
    <app-story-preview *ngIf="group.length > 3" [story]="group[3]" [config]="config"
      [customClasses]="'one-third'"></app-story-preview>
  </div>
  <div class="small-container">
    <app-story-preview *ngIf="group.length > 1" [story]="group[1]" [config]="config"
      [customClasses]="'one-half'"></app-story-preview>
    <app-story-preview *ngIf="group.length > 2" [story]="group[2]" [config]="config"
      [customClasses]="'one-half'"></app-story-preview>
  </div>
</section>