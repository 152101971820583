import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchModel, WebSearchModel } from '../../header/search/search-model';

@Injectable()
export class StorageService {

  private searchItemStories: BehaviorSubject<any> = new BehaviorSubject(new SearchModel());
  private searchItemMedia: BehaviorSubject<any> = new BehaviorSubject(new SearchModel());
  private fullName: BehaviorSubject<string> = new BehaviorSubject('');
  private views: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private searchResult: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private mustRegister: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private anonymousShare: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private legalBanner: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private searchRequestModel: BehaviorSubject<any> = new BehaviorSubject(new WebSearchModel());

  constructor() {
  }

  getSearchItemStories(): Observable<any> {
    return this.searchItemStories.asObservable();
  }

  getSearchItemMedia(): Observable<any> {
    return this.searchItemMedia.asObservable();
  }

  setSearchItemStories(val: any) {
    this.searchItemStories.next(val);
  }

  setSearchItemMedia(val: any) {
    this.searchItemMedia.next(val);
  }

  setName(val: any) {
    this.fullName.next(val);
  }

  getName(): Observable<any> {
    return this.fullName.asObservable();
  }

  setLegalBanner(val: any) {
    this.legalBanner.next(val);
  }

  getLegalBanner(): Observable<any> {
    return this.legalBanner.asObservable();
  }

  setLogin(val: any) {
    this.views.next(val);
  }

  getLogin(): Observable<any> {
    return this.views.asObservable();
  }

  setSearch(val: any) {
    this.searchResult.next(val);
  }

  getSearch(): Observable<any> {
    return this.searchResult.asObservable();
  }

  setSearchModel(val: any) {
    this.searchRequestModel.next(val);
  }

  getSearchModel() {
    return this.searchRequestModel.asObservable();
  }

  getMustRegister() {
    return this.mustRegister.asObservable();
  }

  getAnonymousShare() {
    return this.anonymousShare.asObservable();
  }

  setMustRegister(val: any) {
    this.mustRegister.next(val);
  }

  setAnonymousShare(anonymousShare: boolean) {
    this.anonymousShare.next(anonymousShare);
  }
}
