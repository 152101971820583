export class ToastrConfig {

  public static toastrConfig = {
    closeButton: true,
    timeOut: 2000
  };

  public static error = {
    closeButton: true,
    timeOut: 3000,
    toastClass: 'toast-error',
    messageClass: 'toast-message',
    titleClass: 'toast-title'
  };

  public static success = {
    closeButton: true,
    timeOut: 3000,
    toastClass: 'toast-success',
    messageClass: 'toast-message',
    titleClass: 'toast-title'
  }
}
