<section class="contact flex-center">
  <div class="gray-background"></div>

  <div class="content-wrapper flex-center">
    <h1>
      {{ contactUsResponse.title }}
    </h1>
    <p>{{ contactUsResponse.subtitle }}</p>

    <form name="contact" class="form flex" [formGroup]="valForm" (ngSubmit)="onSubmit($event)">
      <div class="form-data">
        <div class="form-left-side">
          <div class="form-element flex">
            <input [(ngModel)]="contactUsResponse.emailModel.firstName" name="firstname" type="text" id="name"
              placeholder="Enter your first name" formControlName="firstname" />
            <span class="text-danger" *ngIf="
                valForm.controls['firstname'].hasError('required') &&
                (valForm.controls['firstname'].dirty ||
                  valForm.controls['firstname'].touched)
              ">
              {{ language.firstNameRequired }}</span>
          </div>
          <div class="form-element flex">
            <input [(ngModel)]="contactUsResponse.emailModel.lastName" name="lastname" type="text" id="lastname"
              placeholder="Enter your last name" formControlName="lastname" />
            <span class="text-danger" *ngIf="
                valForm.controls['lastname'].hasError('required') &&
                (valForm.controls['lastname'].dirty ||
                  valForm.controls['lastname'].touched)
              ">
              {{ language.lastNameRequired }}</span>
          </div>
          <div class="form-element flex">
            <input [(ngModel)]="contactUsResponse.emailModel.email" name="email" type="text" id="email"
              placeholder="{{language.enterEmail}}" formControlName="email" />
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('required') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">{{ language.emailNameRequired }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">{{ language.invalidEmail }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('maxlength') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">
              {{ language.maxLength50 }}</span>
          </div>
          <div class="form-element flex">
            <input [(ngModel)]="contactUsResponse.emailModel.telephone" name="telephone" type="text" id="phone"
              placeholder="Enter your phone number" formControlName="telephone" />
          </div>
          <div class="form-element flex">
            <input [(ngModel)]="contactUsResponse.emailModel.company" name="company" type="text" id="company"
              placeholder="Enter your company name" formControlName="company" />
          </div>
        </div>

        <div class="form-right-side">
          <div class="form-element message-input flex">
            <textarea [(ngModel)]="contactUsResponse.emailModel.message" name="message" class="form-message"
              name="message" id="message" cols="30" rows="10" placeholder="Enter your message here..."
              formControlName="message"></textarea>
            <span class="text-danger" *ngIf="
                valForm.controls['message'].hasError('required') &&
                (valForm.controls['message'].dirty ||
                  valForm.controls['message'].touched)
              ">
              {{ language.messageRequired }}</span>
          </div>
        </div>
      </div>

      <button class="send-message generic-button" [ngClass]="[mailSent ? 'disabled' : '']" [disabled]="mailSent">
        {{ language.send }}
      </button>
    </form>
    <p *ngIf="message">
      {{ language.emailSentSuccess }}
    </p>
  </div>
  <app-loader *ngIf="loader"></app-loader>
</section>