<section class="login flex">
  <div class="gray-background-login"></div>
  <div class="register-wrapper">
    <div class="selector-container">
      <h1 class="selector-item selector-item-active">
        {{ language.signIn }}
      </h1>
      <h1 class="selector-item">
        <span *ngIf="!layoutResponseModel?.disableRegistration" (click)="registerPage()">{{ language.register }}</span>
      </h1>
    </div>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="submitLoginForm($event)">
      <div class="form-element flex">
        <label for="email">{{ language.email }}</label>
        <input type="email" id="email" name="email" placeholder="{{language.enterEmail}}" [(ngModel)]="loginModel.email"
          formControlName="username" />
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('required') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">
          {{ language.emailNameRequired }}
        </span>
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('email') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">{{ language.invalidEmail }}</span>
      </div>
      <div class="form-element flex">
        <label for="password">{{ language.password }}</label>
        <div class="eye-icon" (click)="visiblePassword()">
          <img *ngIf="showPassword" src="../../assets/images/eye-black.svg" alt="Show password" />
          <img *ngIf="!showPassword" src="../../assets/images/eye-black-hide.svg" alt="Hide password" />
        </div>
        <input type="password" name="password" id="password" placeholder="{{language.enterPassword}}" [type]="type"
          [(ngModel)]="loginModel.password" formControlName="password" />
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('required') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched)
          ">
          Password is required
        </span>
      </div>
      <div class="reset-pass" (click)="forgotPassword()">
        {{ language.forgotPassword }}
      </div>
      <div class="register-buttons flex-center">
        <button type="submit" class="generic-button submit flex-center">
          {{ language.submit }}
        </button>
      </div>
    </form>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>