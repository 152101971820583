import { Component, Input, NgZone, OnInit } from "@angular/core";
import { PageStyle } from "../../../models/style/style.model";
import { TokenManagerService } from "../../services/token-manager.service";
import { StorageService } from "../../services/storage.service";
import { Router } from "@angular/router";
import { McService } from "../../services/mc-service";
import { HttpClient } from "@angular/common/http";
import { FontsAndColorsService } from "../../services/fonts-and-colors.service";

@Component({
  selector: "app-loadersmall",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.less"],
})
export class LoadersmallComponent implements OnInit {
  @Input() class = "";
  styles = new PageStyle();
  user: any;

  constructor(
    private mcService: McService,
    private router: Router,
    private http: HttpClient,
    ngZone: NgZone,
    private sService: StorageService,
    private fontsAndColorsService: FontsAndColorsService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
  }

  ngOnInit() {
    // this.getStyle();
    this.user = TokenManagerService.getUser();
    //window.scrollTo(0, 0);
  }

  setColor(e) {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "current"
      ) as HTMLCollectionOf<HTMLElement>;
      element[0].style.backgroundColor =
        "" + this.styles.style.mainIdentifyingColor;
    });
  }

  // getStyle() {
  //   this.mcService.getStyle()
  //     .subscribe((response) => {
  //         //
  //         this.styles = response;
  //
  //       },
  //       (error) => {
  //         //
  //       });
  // }
}
