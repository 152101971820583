import { Injectable } from "@angular/core";
import { Language } from "../../models/language/language.model";
import { Subject } from "rxjs";

@Injectable()
export class MCLanguageService {
  public language: Subject<any> = new Subject();

  mcLanguage: any = null;

  constructor() {}

  getMCLanguage() {
    // if (!this.mcLanguage)
    //   this.pageLanguage = new Language();
    return this.mcLanguage;
  }

  setMCLanguage(mcLanguage: Language) {
    this.mcLanguage = mcLanguage;
    this.language.next(mcLanguage);
  }
}
