export class ButtonsHelper {
  // -20 is because clientWidth takes into account padding, 10px each side horizontally
  static hoverGenericButton(e, styles) {
    let target = e.target;
    if (!target.classList.contains("generic-button")) {
      target = target.parentNode;
    }
    if (target.classList.contains("generic-button")) {
      target.style.color = styles.style.mainIdentifyingColor;
      target.style["border-color"] = styles.style.mainIdentifyingColor;
      target.style["border-width"] = "2px";
      target.style["border-style"] = "solid";
      target.style.background = styles.style.textButton1Color;
    }
  }
  static unhoverGenericButton(e, styles) {
    let target = e.target;
    if (!target.classList.contains("generic-button")) {
      target = target.parentNode;
    }
    if (target.classList.contains("generic-button")) {
      target.style.color = styles.style.textButton1Color;
      target.style["border-color"] = styles.style.mainIdentifyingColor;
      target.style["border-width"] = "2px";
      target.style["border-style"] = "solid";
      target.style.background = styles.style.mainIdentifyingColor;
    }
  }
  static unhoverEmptyButton(e, styles) {
    let target = e.target;
    if (!target.classList.contains("generic-button")) {
      target = target.parentNode;
    }
    if (target.classList.contains("generic-button")) {
      target.style.color = "#858585";
      target.style["border-color"] = "#858585";
      target.style["border-width"] = "2px";
      target.style["border-style"] = "solid";
      target.style.background = "white";
    }
  }
}
