import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { McService } from "../../../shared/services/mc-service";
import {
  ClientLookupModel,
  SearchModel,
  SearchResultModel,
  WebSearchModel,
} from "./results-search-model";
import { Router } from "@angular/router";
import { StorageService } from "../../../shared/services/storage.service";
import { PageStyle } from "../../../models/style/style.model";
import { FontsAndColorsService } from "../../../shared/services/fonts-and-colors.service";
import { LayoutModel } from "../../../layout/layout-model";
import { LanguageService } from "../../../shared/services/language.service";
import { Language } from "../../../models/language/language.model";
import { DomSanitizer } from "@angular/platform-browser";
import { GoogleAnalyticsEventsService } from "../../../shared/services/google-analytics-events.service";
import { timeStamp } from "console";

@Component({
  selector: "app-results-search",
  templateUrl: "./results-search.component.html",
  styleUrls: ["./results-search.component.less"],
})
export class ResultsSearchComponent implements OnInit, AfterViewInit {
  @Input() layoutResponseModel: LayoutModel;
  @Input() search: boolean;
  @Output() searchChange = new EventEmitter<boolean>();
  @Output() openDateFromEmitter = new EventEmitter<boolean>();
  storiesAndMedia: boolean;
  stories: boolean;
  media: boolean;
  loader: boolean;
  language: Language;

  /*
  dateFrom: Date = new Date(new Date().setDate(new Date().getDate() - 31));
  dateTo: Date = new Date();
  */

  dateFrom: Date = null;
  dateTo: Date = null;

  dateFromButton: boolean;
  dateToButton: boolean;

  result: any;
  styles = new PageStyle();
  headerFooter: any;
  searchModelData = new SearchModel();
  searchResultResponse = new SearchResultModel();
  checked = false;
  checkedb = false;
  searchFrom = "stories";

  constructor(
    private mcService: McService,
    private languageService: LanguageService,
    private router: Router,
    private storageService: StorageService,
    private fontsAndColorsService: FontsAndColorsService,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private googleAnalytics: GoogleAnalyticsEventsService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  @HostListener("document:keyup", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeSearch();
    }
    if (event.keyCode === 13) {
      this.searchMc();
    }
  }

  ngOnInit() {
    this.headerFooter = JSON.parse(localStorage.getItem("headerfooter"));
    this.getSearch();
  }

  ngAfterViewInit() { }

  openDateFrom() {
    this.dateFromButton = !this.dateFromButton;
  }

  openDateTo() {
    this.dateToButton = !this.dateToButton;
  }

  changeFromDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        this.dateTo = new Date();
      }
    }
  }

  changeToDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        const dateTo: Date = new Date(this.dateTo);
        this.dateFrom = new Date(dateTo.getTime());
      }
    }
  }

  hoverBtn(e) {
    e.target.style.background = this.styles.style.mainIdentifyingColor;
  }

  outBtn(e) {
    if (e.target.className.indexOf("active") > -1) {
      e.target.style.background = this.styles.style.mainIdentifyingColor;
    } else {
      e.target.style.background = "white";
    }
  }

  checkCustomCategories() {
    // if (this.checked == false) {
    //   for (const x of this.searchModelData.customCategories) {
    //     x.marked = true;
    //     if (x.marked) {
    //       this.searchModelData.searchModel.customCategoryIds.push(x.id);
    //     } else {
    //       this.searchModelData.searchModel.customCategoryIds = [];
    //     }
    //   }
    //   this.checked = true;
    //   var elements = document.getElementsByClassName(
    //     "allS"
    //   ) as HTMLCollectionOf<HTMLElement>;
    //   for (let i = 0; i < elements.length; i++) {
    //     elements[i].style.backgroundColor =
    //       this.styles.style.mainIdentifyingColor;
    //   }
    // } else {
    //   for (const x of this.searchModelData.customCategories) {
    //     x.marked = false;
    //     if (x.marked) {
    //       this.searchModelData.searchModel.customCategoryIds.push(x.id);
    //     } else {
    //       this.searchModelData.searchModel.customCategoryIds = [];
    //     }
    //   }
    //   this.checked = false;
    //   var elements = document.getElementsByClassName(
    //     "allS"
    //   ) as HTMLCollectionOf<HTMLElement>;
    //   for (let i = 0; i < elements.length; i++) {
    //     elements[i].style.backgroundColor = "white";
    //   }
    // }
  }

  checkMainCategories() {
    // if (this.checked == false) {
    //   for (const x of this.searchModelData.mainCategories) {
    //     x.checked = true;
    //     if (x.checked) {
    //       this.searchModelData.searchModel.mainCategoryIds.push(x.id);
    //     } else {
    //       this.searchModelData.searchModel.mainCategoryIds = [];
    //     }
    //   }
    //   this.checked = true;
    //   var elements = document.getElementsByClassName(
    //     "allS"
    //   ) as HTMLCollectionOf<HTMLElement>;
    //   for (let i = 0; i < elements.length; i++) {
    //     elements[i].style.backgroundColor =
    //       this.styles.style.mainIdentifyingColor;
    //   }
    // } else {
    //   for (const x of this.searchModelData.mainCategories) {
    //     x.checked = false;
    //     if (x.checked) {
    //       this.searchModelData.searchModel.mainCategoryIds.push(x.id);
    //     } else {
    //       this.searchModelData.searchModel.mainCategoryIds = [];
    //     }
    //   }
    //   this.checked = false;
    //   var elements = document.getElementsByClassName(
    //     "allS"
    //   ) as HTMLCollectionOf<HTMLElement>;
    //   for (let i = 0; i < elements.length; i++) {
    //     elements[i].style.backgroundColor = "white";
    //   }
    // }
  }

  checkBrands() {
    if (this.checkedb == false) {
      for (const x of this.searchModelData.subClients) {
        x.checked = true;
        if (x.checked) {
          this.searchModelData.searchModel.subClientIds.push(x.id);
        } else {
          this.searchModelData.searchModel.subClientIds = [];
        }
      }
      this.checkedb = true;
      var elements = document.getElementsByClassName(
        "allSb"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.backgroundColor =
          this.styles.style.mainIdentifyingColor;
      }
    } else {
      for (const x of this.searchModelData.subClients) {
        x.checked = false;
        if (x.checked) {
          this.searchModelData.searchModel.subClientIds.push(x.id);
        } else {
          this.searchModelData.searchModel.subClientIds = [];
        }
      }
      this.checkedb = false;
      var elements = document.getElementsByClassName(
        "allSb"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.backgroundColor = "white";
      }
    }
  }

  getSearch() {
    this.mcService.getSearch().subscribe(
      (response: any) => {
        this.searchModelData = response;
        // this.searchModelData.subClients = [
        //   {
        //     parentId: null,
        //     id: 1,
        //     label: "brand",
        //     checked: false,
        //   },
        // ];
        this.searchModelData.searchModel = new WebSearchModel();
        if (localStorage.getItem("searchModel") !== null) {
          this.searchModelData.searchModel = JSON.parse(
            localStorage.getItem("searchModel")
          );
          // if (this.searchModelData.mainCategories == null) this.searchModelData.mainCategories = [];
          // if (this.searchModelData.customCategories == null) this.searchModelData.customCategories = [];
          if (this.searchModelData.subClients == null)
            this.searchModelData.subClients = [];
          if (this.searchModelData.searchModel.stories)
            this.searchFrom = "stories";
          if (this.searchModelData.searchModel.media) this.searchFrom = "media";
          // this.searchModelData.mainCategories.forEach(x => x.checked = this.searchModelData.searchModel.mainCategoryIds.indexOf(x.id) > -1);
          // this.searchModelData.customCategories.forEach(x => x.marked = this.searchModelData.searchModel.customCategoryIds.indexOf(x.id) > -1);
          this.searchModelData.subClients.forEach(
            (x) =>
            (x.checked =
              this.searchModelData.searchModel.subClientIds.indexOf(x.id) >
              -1)
          );
        } else {
          this.setSearchFrom(this.searchFrom);
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        //
      }
    );
  }

  filterUniqueCategories(categories) {
    return categories.filter((value, index, self) => {
      return self.findIndex((cat) => cat.label === value.label) === index;
    });
  }

  customChecked(custom) {
    // custom.marked = !custom.marked;
    // let customCategories = this.searchModelData?.customCategories.filter((c) => c.label == custom.label);
    // let customCategoriesId = customCategories.map((c) => c.id);
    // if (custom.marked) {
    //   customCategoriesId.forEach((c) => this.searchModelData.searchModel.customCategoryIds.push(c));
    //   this.googleAnalytics.emitEvent('SearchCategory', 'SearchCategory', 'CategorySelected', custom.label);
    // } else {
    //   this.searchModelData.searchModel.customCategoryIds = this.searchModelData.searchModel.customCategoryIds.filter((c) => !customCategoriesId.includes(c));
    // }
  }

  mainChecked(category) {
    // category.checked = !category.checked;
    // if (category.checked) {
    //   this.searchModelData.searchModel.mainCategoryIds.push(category.id);
    //   this.googleAnalytics.emitEvent('SearchCategory', 'SearchCategory', 'CategorySelected', category.label);
    // } else {
    //   this.searchModelData.searchModel.mainCategoryIds =
    //     this.searchModelData.searchModel.mainCategoryIds.filter(x => x !== category.id);
    // }
  }

  brandChecked(brand) {
    brand.checked = !brand.checked;
    const index = this.searchModelData.searchModel.subClientIds.indexOf(brand);
    if (brand.checked) {
      this.searchModelData.searchModel.subClientIds.push(brand.id);
      this.googleAnalytics.emitEvent(
        "SearchBrand",
        "SearchBrand",
        "BrandSelected",
        brand.label
      );
    } else {
      this.searchModelData.searchModel.subClientIds.splice(index, 1);
    }
  }

  searchMc() {
    this.loader = true;
    localStorage.setItem(
      "searchModel",
      JSON.stringify(this.searchModelData.searchModel)
    );
    if (this.searchModelData.searchModel.stories) {
      this.searchModelData.searchModel.languageId = Number(
        localStorage.getItem("lng")
      );

      this.searchModelData.searchModel.page = 1;
      this.searchModelData.searchModel.pageSize = 12;
      this.mcService
        .searchMcStories(this.searchModelData.searchModel)
        .subscribe(
          (response: any) => {
            this.searchResultResponse = response;
            this.layoutResponseModel.headerLinks.forEach(
              (x) => (x.active = false)
            );
            this.layoutResponseModel.footerLinks.forEach(
              (x) => (x.active = false)
            );

            for (const result of this.searchResultResponse.stories) {
              if (result.featuredEmbeddedCode) {
                result.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
                  result.featuredEmbeddedCode
                );
              }
            }
            if (this.searchResultResponse.stories) {
              this.layoutResponseModel.headerLinks.forEach(
                (x) => (x.active = false)
              );
            }
            if (
              this.searchResultResponse.mustRegisterToAccess &&
              localStorage.getItem("Token") == null
            ) {
              this.closeSearch();
              this.loader = false;
              sessionStorage.setItem(
                "redirectUrl",
                "/" + window.location.pathname
              );
              this.router.navigateByUrl("/sign-in");
            } else {
              this.storageService.setSearchItemStories(
                this.searchResultResponse
              );
              this.closeSearch();
              this.loader = false;
              this.router.navigateByUrl("/results");
            }

            this.storageService.setSearchModel(
              this.searchModelData.searchModel
            );
          },
          (error) => {
            this.searchModelData.searchModel.stories = false;
          }
        );
    }

    if (this.searchModelData.searchModel.media) {
      this.searchModelData.searchModel.languageId = Number(
        localStorage.getItem("lng")
      );
      this.searchModelData.searchModel.page = 1;
      this.searchModelData.searchModel.pageSize = 12;

      this.mcService.searchMcMedia(this.searchModelData.searchModel).subscribe(
        (response: any) => {
          this.searchResultResponse = response;
          this.layoutResponseModel.headerLinks.forEach(
            (x) => (x.active = false)
          );
          this.layoutResponseModel.footerLinks.forEach(
            (x) => (x.active = false)
          );

          if (this.searchResultResponse.media) {
            this.layoutResponseModel.headerLinks.forEach(
              (x) => (x.active = false)
            );
          }
          if (
            this.searchResultResponse.mustRegisterToAccess &&
            localStorage.getItem("Token") == null
          ) {
            this.closeSearch();
            this.loader = false;
            sessionStorage.setItem(
              "redirectUrl",
              "/" + window.location.pathname
            );
            this.router.navigateByUrl("/sign-in");
          } else {
            this.storageService.setSearchItemMedia(this.searchResultResponse);
            this.closeSearch();
            this.loader = false;
            this.router.navigateByUrl("/results");
          }

          this.storageService.setSearchModel(this.searchModelData.searchModel);
        },
        (error) => {
          this.searchModelData.searchModel.media = false;
        }
      );
    }
  }

  closeSearch() {
    this.searchChange.emit(false);
    localStorage.setItem(
      "searchModel",
      JSON.stringify(this.searchModelData.searchModel)
    );
    const body = document.getElementsByTagName("body")[0];
    body.style.position = "inherit";
  }

  setSearchFrom(tab) {
    this.searchFrom = tab;
    this.searchModelData.searchModel.media = tab != "stories";
    this.searchModelData.searchModel.stories = tab != "media";
    if (this.searchModelData.searchModel.media) {
      this.searchModelData.searchModel.images = true;
      this.searchModelData.searchModel.video = true;
      this.searchModelData.searchModel.document = true;
      this.searchModelData.searchModel.audio = true;
    } else {
      this.searchModelData.searchModel.images = false;
      this.searchModelData.searchModel.video = false;
      this.searchModelData.searchModel.document = false;
      this.searchModelData.searchModel.audio = false;
    }
  }

  clearAll() {
    this.searchModelData.searchModel = new WebSearchModel();
    // this.searchModelData.mainCategories.forEach(x => x.checked = false);
    this.searchModelData.subClients.forEach((x) => (x.checked = false));
    // this.searchModelData.customCategories.forEach(x => x.marked = false);

    this.setSearchFrom("stories");

    localStorage.removeItem("searchModel");
  }
}
