import { Component, OnInit } from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import { TermsModel } from "./terms-model";
import { Router } from "@angular/router";
import { PageStyle } from "../../models/style/style.model";
import { Language } from "../../models/language/language.model";
import { LanguageService } from "../../shared/services/language.service";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.less"],
})
export class TermsComponent implements OnInit {
  loader: boolean;
  termsResponseModel = new TermsModel();
  styles: PageStyle;
  language: Language;

  constructor(private mcService: McService, private router: Router, private languageService: LanguageService,
  ) {
    this.language = languageService.getLanguage();
  }

  ngOnInit() {
    this.getTerms();
  }

  getTerms() {
    this.loader = true;
    this.mcService.getTerms().subscribe(
      (response: any) => {
        this.termsResponseModel = response;
        this.loader = false;
      },
      (error) => { }
    );
  }
}
