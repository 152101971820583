import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageStyle } from '../../../models/style/style.model';
import { FontsAndColorsService } from '../../services/fonts-and-colors.service';
import { TokenManagerService } from '../../services/token-manager.service';
import { McService } from '../../services/mc-service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { FacebookService } from 'ngx-facebook';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.less']
})
export class ShareButtonComponent implements OnInit {

  @Input() social: string;
  @Input() open: boolean;
  @Input() shared: number;
  @Input() storyId: number = null;
  @Input() staggingStoryId: number = null;
  @Input() mediaId: number = null;
  @Input() sbUrl: string;
  @Input() description: string;
  @Input() title: string;
  @Input() image: string;
  @Input() isMediaShare: boolean = false;
  @Output() emitShared = new EventEmitter();

  embeddedPopupShow: boolean;
  user: any;
  hovered = false;
  styles = new PageStyle();

  constructor(private fb: FacebookService, private fontsAndColorsService: FontsAndColorsService, private mcService: McService, private googleAnalytics: GoogleAnalyticsEventsService) {

    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    fb.init({
      appId: headerFooter.facebookAppId,
      version: 'v6.0'
    });
    this.styles = fontsAndColorsService.getPageStyleInfo();
  }

  ngOnInit() {
    if (this.sbUrl) {
    }
  }

  getUrlForEmail() {
    return this.sbUrl.replace(/(^\w+:|^)\/\//, '');
  }

  openShare($event) {
    $event.stopPropagation();
    this.open = !this.open;
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.open = false;
    }
  }

  openEmbeddedPopup() {
    this.embeddedPopupShow = true;
  }

  closeEmbeddedPopup() {
    this.embeddedPopupShow = false;

  }

  clickSharedNews() {
    this.user = TokenManagerService.getUser();

    if (localStorage.getItem('Token') === null) {
      this.user.id = 'anonymous';
    }

    let networkType = this.getNetworkType(this.social);

    switch (networkType) {
      case 'facebook':
        this.fb.ui({
          method: 'share_open_graph',
          action_type: 'og.shares',
          action_properties: JSON.stringify({
            object: {
              'og:url': this.sbUrl,
              'og:title': this.title,
              'og:description': this.description,
              'og:image': this.image,
            }
          })
        }).then(res => {
          //this.shared++;
          if (this.user.cmsUser == null)
            this.shareAnalytics(networkType);
        }).catch(e => {
          //
        });
        break;

      case 'mail':
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        if (iOS) {
          try {
            (window as any).webkit.messageHandlers.shareByEmailClicked.postMessage({
              url: this.sbUrl,
              title: this.title,
              subject: this.title
            });
          } catch (err) {
          }

        }
        window.open(`mailto:?subject=${this.title}&body=${this.description}%0D%0A%0D%0A%0D%0ALink to story: ${this.getUrlForEmail()}`, "_blank");
        if (this.user.cmsUser == null)
          this.shareAnalytics(networkType);

        break;
      default:
        if (this.user.cmsUser == null)
          this.shareAnalytics(networkType);
    }

  }

  private shareAnalytics(networkType) {
    if (this.isMediaShare) {
      this.mcService.addSharedToMedia(this.mediaId, this.user.id, networkType, false, true).subscribe();
      this.googleAnalytics.emitEvent('share', `media/${this.mediaId.toString()}`, `share/${networkType}`, this.user.id);
    } else {
      this.mcService.addSharedToNews(this.storyId, this.user.id, networkType, true).subscribe();
      this.googleAnalytics.emitEvent('share', `story/${this.staggingStoryId.toString()}`, `share/${networkType}`, this.user.id);
    }
    this.shared++;
    this.emitShared.emit(this.shared);
  }

  share() {
    if (this.social.toLowerCase().indexOf('embed') > -1) {
      this.openEmbeddedPopup();
    } else {
      this.clickSharedNews();
    }
  }

  getNetworkType(social = this.social) {
    if (social.toLowerCase().indexOf('facebook') > -1)
      return 'facebook';
    if (social.toLowerCase().indexOf('twitter') > -1)
      return 'twitter';
    if (social.toLowerCase().indexOf('linkedin') > -1)
      return 'linkedin';
    if (social.toLowerCase().indexOf('mail') > -1)
      return 'mail';
    if (social.toLowerCase().indexOf('embed') > -1)
      return 'code';

    return '';
  }

  getNetworkTypeClass(social = this.social) {
    if (social.toLowerCase().indexOf('facebook') > -1)
      return 'fab fa-facebook';
    if (social.toLowerCase().indexOf('twitter') > -1)
      return 'fab fa-twitter';
    if (social.toLowerCase().indexOf('linkedin') > -1)
      return 'fab fa-linkedin';
    if (social.toLowerCase().indexOf('mail') > -1)
      return 'fas fa-envelope';
    if (social.toLowerCase().indexOf('embed') > -1)
      return 'fas fa-code';

    return '';
  }

  hasShareButton() {
    let social = this.getNetworkType();
    if (social.toLowerCase().indexOf('linkedin') > -1 || social.toLowerCase().indexOf('twitter') > -1)
      return true;

    return false;
  }
}
