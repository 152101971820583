<section class="popup-background">
  <div class="popup-overlay"></div>
  <div class="popup">
    <div class="close-icon" (click)="close()"></div>
    <div class="popup-content-wrapper">
      <div class="terms-bar flex-center">
        <h2 class="section-headline">{{ language.termsAndConditions }}</h2>
      </div>
      <div class="headline">{{ language.disclaimer }}</div>
      <div class="terms-wrapper flex">
        <p [innerHTML]="termsResponseModel.terms"></p>
        <p [innerHTML]="termsResponseModel.condition"></p>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
</section>