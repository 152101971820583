import { fromEvent as observableFromEvent, Observable } from "rxjs";

import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-debounce",
  templateUrl: "./debounce.component.html",
  styleUrls: ["./debounce.component.less"],
})
export class DebounceComponent implements OnInit {
  @Input() placeholder: string;
  @Input() delay = 500;
  @Input() styles: any;
  @Input() cssClass: string;
  @Output() value: EventEmitter<string> = new EventEmitter();

  @Input() inputValue: string;

  constructor(private elementRef: ElementRef) {
    const eventStream = observableFromEvent(elementRef.nativeElement, "keyup")
      .pipe(map(() => this.inputValue))
      .pipe(debounceTime(this.delay), distinctUntilChanged());

    eventStream.subscribe((input) => this.value.emit(input));
  }

  ngOnInit(): void {
    
    
  }
}
