
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';



// import {TokenManagerService} from './token-login.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders } from '@angular/common/http';

import { ErrorService } from "./error-service";
import { ToastrConfig } from '../helpers/toastr-config';

@Injectable()
export class HttpRequestService {


  constructor(private http: HttpClient, private toastr: ToastrService, private errorService: ErrorService, private router: Router, private storageService: StorageService) {
  }

  handleError(error: any, url: string, data?: any, noErrorToast?: boolean, handleError: boolean = true) {

    if (error.status && error.status === 401 && handleError) {
      localStorage.removeItem('Token');
      localStorage.removeItem('User')
      sessionStorage.removeItem('mustRegister');
      this.storageService.setName("");
      this.router.navigate(['sign-in']);
      return observableThrowError("Login required.");
    }

    let errMsg: any;
    errMsg = error.error.message ? error.error.message : error.toString();

    if (errMsg === 'User does not exist!' && handleError) {
      localStorage.removeItem('Token');
      localStorage.removeItem('User')
      sessionStorage.removeItem('mustRegister');
      this.storageService.setName("");
      this.router.navigate(['sign-in']);
      return;
    }

    //

    let headerFooter = null;
    if (localStorage.getItem('headerfooter'))
      headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    if (error.status >= 500 && headerFooter && headerFooter.customErrorRedirect && handleError) {
      let action = url;
      if (data) {
        action = url + '---' + JSON.stringify(data);
      }

      this.errorService.setError({
        action: action,
        message: errMsg
      });
      this.router.navigate(['/error']);

      return observableThrowError(errMsg);
    }

    if (!noErrorToast) {
      this.toastr.error(errMsg, 'Oh Snap!', ToastrConfig.error);
    }

    return observableThrowError(errMsg);
  }

  get(url: string, showSuccessToast?: boolean, toastMessage?: string, noErrorToast?: boolean) {
    const result = this.http.get(url).pipe(
      map((response: Response) => {
        if (showSuccessToast) {
          this.toastr.success(toastMessage ? toastMessage : 'Operation successful.', 'Well Done!', ToastrConfig.success);
        }
        return response;
      }),
      catchError((response) => this.handleError(response, url, null, noErrorToast)));
    return result;
  }

  post(url: string, request: any, showSuccessToast?: boolean, toastMessage?: string, noErrorToast?: boolean, handleError: boolean = true) {
    const result = this.http.post(url, request).pipe(
      map((response: Response) => {
        if (showSuccessToast) {
          this.toastr.success(toastMessage ? toastMessage : 'Operation successful.', 'Well Done!', ToastrConfig.success);
        }
        return response;
      }),
      catchError((response) => this.handleError(response, url, request, noErrorToast, handleError)));
    return result;
  }

  delete(url: string, showSuccessToast?: boolean, toastMessage?: string, noErrorToast?: boolean) {
    const result = this.http.delete(url).pipe(
      map((response: Response) => {
        if (showSuccessToast) {
          this.toastr.success(toastMessage ? toastMessage : 'Operation successful.', 'Well Done!', ToastrConfig.success);
        }
        if (response == null) {
          return null;
        }

        return response;
      }),
      catchError((response) => this.handleError(response, url, null, noErrorToast)));
    return result;
  }

  put(url: string, request: any, showSuccessToast?: boolean, toastMessage?: string, noErrorToast?: boolean) {
    const result = this.http.put(url, request).pipe(
      map((response: Response) => {
        if (showSuccessToast) {
          this.toastr.success(toastMessage ? toastMessage : 'Operation successful.', 'Well Done!', ToastrConfig.success);
        }

        return response;
      }),
      catchError((response) => this.handleError(response, url, request, noErrorToast)));
    return result;
  }

  postMultipart(url: string, data: any, files: File[], showSuccessToast?: boolean, toastMessage?: string, noErrorToast?: boolean) {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    for (const file of files) {
      formData.append(file.name, file);
    }
    const result = this.http.post(url, formData).pipe(
      map((response: Response) => {
        // if (response == null) {
        //   return null;
        // } else {

        if (showSuccessToast) {
          this.toastr.success(toastMessage ? toastMessage : 'Operation successful.', 'Well Done!', ToastrConfig.success);
        }

        return response;
        // }
      }),
      catchError((response) => this.handleError(response, url, data, noErrorToast)));
    return result;
  }
}
