import {Injectable} from '@angular/core';
import {TokenManagerService} from './token-manager.service';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {McService} from './mc-service';
import {stat} from 'fs';
import {StorageService} from "./storage.service";

@Injectable()
export class AuthGuardService {
  headerFooter: any;

  constructor(private tokenManagerService: TokenManagerService, private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));
    return this.tokenManagerService.isAuthenticated()
      .then(
        (authenticated: boolean) => {
          if (authenticated) {
            return true;
          } else {
            this.router.navigate(['/sign-in'], {replaceUrl: true});
          }
        }
      );
  }
}

@Injectable()
export class AuthGuard {
  headerFooter: any;

  constructor(private tokenManagerService: TokenManagerService, private router: Router, private mcService: McService) {
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let headerFooter = JSON.parse(localStorage.getItem('headerfooter'));
    if (!headerFooter) {
      this.mcService.getHeaderFooter(1)
        .subscribe((response) => {
            localStorage.setItem('headerfooter', JSON.stringify(response));
            headerFooter = JSON.parse(localStorage.getItem('headerfooter'));
            this.checkRoute(state, headerFooter);
          },
          (error) => {
            //
          });
    } else {
      this.checkRoute(state, headerFooter);
    }
    return true;
  }

  checkRoute(state, headerFooter) {
    let url = false;
    for (const x of headerFooter.footerLinks) {
      if (x.url.toLowerCase() === state.url.split('/')[1]) {
        url = true;
        break;
      }
    }
    for (const y of headerFooter.headerLinks) {
      if (y.url.toLowerCase() === state.url.split('/')[1]) {
        url = true;
        break;
      }
    }

    if (url) {
      return true;
    } else {
      this.router.navigateByUrl('/');
    }
  }

}

@Injectable()
export class MustRegisterGuard {
  headerFooter: any;

  constructor(private tokenManagerService: TokenManagerService, private router: Router, private storageService: StorageService) {
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (TokenManagerService.getToken()) return true;

    sessionStorage.setItem('redirectUrl', '/' + state.url);

    if (sessionStorage.getItem('mustRegister')) {
      const mustRegister = JSON.parse(sessionStorage.getItem('mustRegister'));

      this.storageService.setMustRegister(mustRegister);

      if (!mustRegister) {
        return true;
      } else {
        this.router.navigate(['/sign-in']);
      }

    } else {
      this.tokenManagerService.mustRegister()
        .subscribe(
          (result) => {

            const model: any = result;

            sessionStorage.setItem('mustRegister', model.mustRegister);
            sessionStorage.setItem('anonymousShare', model.anonymousShare);

            this.storageService.setMustRegister(model.mustRegister);
            this.storageService.setAnonymousShare(model.anonymousShare);

            if (!model.mustRegister) {
              this.router.navigate([state.url]);
              return;
            } else {
              this.router.navigate(['/sign-in']);
            }
          }
        );
    }


  }
}

@Injectable()
export class AnonymousShareGuard {
  headerFooter: any;

  constructor(private tokenManagerService: TokenManagerService, private router: Router, private storageService: StorageService) {
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (TokenManagerService.getToken()) return true;

    sessionStorage.setItem('redirectUrl', '/' + state.url);

    if (sessionStorage.getItem('mustRegister') && sessionStorage.getItem('anonymousShare')) {
      const mustRegister = JSON.parse(sessionStorage.getItem('mustRegister'));
      const anonymousShare = JSON.parse(sessionStorage.getItem('anonymousShare'));

      this.storageService.setMustRegister(mustRegister);
      this.storageService.setAnonymousShare(anonymousShare);

      if (anonymousShare) {
        return true;
      }

      if (!mustRegister) {
        return true;
      } else {
        this.router.navigate(['/sign-in']);
      }

    } else {
      this.tokenManagerService.mustRegister()
        .subscribe(
          (result) => {

            const model:any = result;

            sessionStorage.setItem('mustRegister', model.mustRegister);
            sessionStorage.setItem('anonymousShare', model.anonymousShare);

            this.storageService.setMustRegister(model.mustRegister);
            this.storageService.setAnonymousShare(model.anonymousShare);

            if (model.anonymousShare) {
              this.router.navigate([state.url]);
              return;
            }

            if (!model.mustRegister) {
              this.router.navigate([state.url]);
              return;
            } else {
              this.router.navigate(['/sign-in']);
            }
          }
        );
    }


  }
}
