<div class="my-files-container">
  <ng-container *ngIf="!showLoginMessage && cart.length > 0">
    <div class="my-files-header-bar">
      <div class="items-counter">
        {{ language.itemsInBox }} ({{ cart.length || 0 }})
      </div>
      <div
        class="clear-my-files"
        [ngStyle]="{
          color: styles.style.mainIdentifyingColor
        }"
        (click)="deleteAll()"
      >
        {{ language.deleteAll }}
      </div>
    </div>
    <div class="my-files-separator"></div>
    <div class="my-files-list" *ngIf="!loader">
      <div
        class=""
        *ngFor="let dl of downloadMediaResponse?.downloadBoxList; let i = index"
      >
        <div class="" *ngFor="let dl of dl.media">
          <div class="my-file-container">
            <picture
              class="my-file-image"
              *ngIf="dl.media.mediaType === 'Image'"
            >
              <source
                class="my-file-image"
                srcset="{{ webPHelper.changeFileToWebP(dl.media.thumbUrl) }}"
                type="image/webp"
              />
              <source
                class="my-file-image"
                srcset="{{ dl.media.thumbUrl }}"
                type="image/jpeg"
              />
              <img
                class="my-file-image"
                (error)="webPHelper.removeWebP($event)"
                src="{{ dl.media.thumbUrl }}"
                alt="File image"
              />
            </picture>
            <img
              src="{{ dl.media.thumbUrl }}"
              *ngIf="dl.media.mediaType === 'Document'"
              onerror="this.src = 'assets/images/document_default.png'"
              alt=""
              class="my-file-image"
            />
            <img
              src="{{ dl.media.thumbUrl }}"
              *ngIf="dl.media.mediaType === 'Audio'"
              onerror="this.src = 'assets/images/audio_default.png'"
              alt=""
              class="my-file-image"
            />
            <img
              src="{{ dl.media.thumbUrl }}"
              *ngIf="dl.media.mediaType === 'Video'"
              class="my-file-image"
            />
            <div class="my-file-info">
              <div class="my-file-title">
                {{ dl.media.name | slice : 0 : 10
                }}<span *ngIf="dl.media.name.length >= 10">...</span>
              </div>
              <div
                class="my-file-delete"
                [ngStyle]="{
                  color: styles.style.mainIdentifyingColor
                }"
                (click)="deleteFile(dl.media.id)"
              >
                {{ language.delete }}
              </div>
            </div>
          </div>
          <div class="my-files-separator"></div>
        </div>
      </div>
    </div>
    <div class="loader-container" *ngIf="loader">
      <app-loaderrelative></app-loaderrelative>
    </div>
    <div class="my-files-footer-bar">
      <div
        class="my-files-footer-button generic-button my-files-download"
        [ngStyle]="{
          color: styles.style.textButton1Color,
          background: styles.style.mainIdentifyingColor
        }"
        (mouseover)="buttonsHelper.hoverGenericButton($event, styles)"
        (mouseout)="buttonsHelper.unhoverGenericButton($event, styles)"
        (click)="download()"
        *ngIf="!downloadActive"
      >
        {{ language.download }}
      </div>

      <div
        class="my-files-footer-button generic-button my-files-cancel-download"
        [ngStyle]="{
          color: styles.style.textButton1Color,
          background: styles.style.mainIdentifyingColor
        }"
        (mouseover)="buttonsHelper.hoverGenericButton($event, styles)"
        (mouseout)="buttonsHelper.unhoverGenericButton($event, styles)"
        (click)="cancelDownload()"
        *ngIf="downloadActive"
      >
        {{ language.cancel }}
      </div>
      <div
        class="my-files-footer-button generic-button my-files-share"
        [ngStyle]="{
          color: styles.style.textButton1Color,
          background: styles.style.mainIdentifyingColor
        }"
        (mouseover)="buttonsHelper.hoverGenericButton($event, styles)"
        (mouseout)="buttonsHelper.unhoverGenericButton($event, styles)"
        (click)="shareCart()"
      >
        {{ language.share }}
      </div>
    </div>
    <div class="my-files-footer-bar share-footer" *ngIf="shareCartUrl">
      <div class="share-cart-url">{{ shareCartUrl }}</div>
      <div
        class="generic-button clipboard-button"
        ngxClipboard
        (cbOnSuccess)="copiedUrl = shareCartUrl"
        [cbContent]="shareCartUrl"
        (click)="$event.stopPropagation()"
      >
        <span *ngIf="copiedUrl == shareCartUrl">Copied!</span>
        <span *ngIf="copiedUrl != shareCartUrl">Copy to clipboard</span>
      </div>
    </div>
  </ng-container>
  <div class="my-files-message" *ngIf="showLoginMessage">
    {{ language.logInMyFiles }}
  </div>
  <div class="my-files-message" *ngIf="cart.length == 0">
    {{ language.myFilesNoFiles }}
  </div>
</div>
