
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { McService } from '../../services/mc-service';
import { TokenManagerService } from '../../services/token-manager.service';
import { StorageService } from '../../services/storage.service';
import { fbModel, LoginModel } from '../../../login/login-model';
import { FacebookService, LoginResponse } from 'ngx-facebook';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { LayoutModel } from "../../../layout/layout-model";
import { FontsAndColorsService } from '../../services/fonts-and-colors.service';
import { PageStyle } from '../../../models/style/style.model';
import { LanguageService } from '../../../shared/services/language.service';
import { Language } from '../../../models/language/language.model';
import { SavedStoryService } from '../../services/saved-story.service';
import { MyFilesService } from '../../services/my-files.service';

@Component({
  selector: 'app-loginpopup',
  templateUrl: './loginpopup.component.html',
  styleUrls: ['./loginpopup.component.less']
})
export class LoginpopupComponent implements OnInit {
  @Input() layoutResponseModel: LayoutModel;

  @Input() loginPopup: boolean;
  @Output() loginPopupChange = new EventEmitter<boolean>();
  @Output() loginSuccessfulChange = new EventEmitter<boolean>();
  loggedUserName: string;
  loginModel = new LoginModel();
  response: any;
  loader: boolean;
  valForm: FormGroup;
  fbData = new fbModel();
  token: string;
  styles = new PageStyle();
  language: Language;


  constructor(private mcService: McService,
    private languageService: LanguageService,
    private fb: FacebookService,
    private form: FormBuilder,
    private authLoginService: TokenManagerService,
    private router: Router,
    private sService: StorageService,
    public fontsAndColorsService: FontsAndColorsService,
    private savedStoryService: SavedStoryService,
    private myFilesService: MyFilesService) {
    //
    this.valForm = this.form.group({
      'password': [null, Validators.required],
      'username': [null, Validators.compose([Validators.required, CustomValidators.email])],
    });

    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    fb.init({
      appId: headerFooter.facebookAppId,
      version: 'v6.0'
    });
    const token = JSON.parse(localStorage.getItem('Token'));
    this.token = token && token.token;
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close();
    }
  }

  ngOnInit() {
    // if(localStorage.getItem('Token') !== null){
    //   this.router.navigateByUrl('/Home');
    // }
    window.scrollTo(0, 0);

    this.sService.getLogin().subscribe(term => {
      this.layoutResponseModel = term;
    });
  }

  login() {
    this.fb.login()
      .then((res: LoginResponse) => {
        this.mcService.logInFb(res.authResponse.accessToken)
          .subscribe((response: any) => {
            //
            const token = response.token;
            if (token) {
              this.token = token;

              if (response.user.role === 3 || response.user.role === 4 || response.user.role === 5 || response.user.role === 6) {
                return observableThrowError('Wrong email or password!');
              }
              localStorage.setItem('Token', JSON.stringify({ token: token }));
              localStorage.setItem('User', JSON.stringify({ user: response.user }));
              const files = this.myFilesService.getFiles();
              this.myFilesService.setMyFiles(files);
              this.sService.setName(response.user.fullName);
              this.router.navigate(['/']);
            } else {
              return false;
            }
          },
            (error) => {
              //
            });
      })
      .catch(this.handleError);
  }

  st() {
    this.fb.api('/me?fields=email,first_name,last_name').then(
      (response) => {
        //
        this.fbData.email = response.email;
        this.fbData.facebookId = response.id;
        this.fbData.firstName = response.first_name;
        this.fbData.lastName = response.last_name;
        // this.loginModel.email=response.email;
        // this.loginModel.password='';
        this.mcService.logInFb(this.fbData)
          .subscribe((response: any) => {

            const token = response.token;
            if (token) {
              this.token = token;
              

              if (response.user.role === 3 || response.user.role === 4 || response.user.role === 5 || response.user.role === 6) {
                return observableThrowError('Wrong email or password!');
              }
              localStorage.setItem('Token', JSON.stringify({ token: token }));
              localStorage.setItem('User', JSON.stringify({ user: response.user }));
              const files = this.myFilesService.getFiles();
              this.myFilesService.setMyFiles(files);
              this.sService.setName(response.user.fullName);
              this.router.navigate(['/']);
            } else {
              return false;
            }

          },
            (error) => {
              //
            });
      }
    )
      .catch(this.handleError);
  }


  registerPage() {
    this.router.navigateByUrl('/register');
  }


  private handleError(error) {
    console.error('Error processing action', error);
  }

  submitLoginForm(e) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.loader = true;
      this.authLoginService.login(this.loginModel)
        .subscribe((response: any) => {
          this.response = response;
          this.loader = false;
          this.loggedUserName = TokenManagerService.getUser().fullName;
          // this.isPassowordSet = TokenManagerService.getUser().isPasswordSet;
          this.sService.setName(this.loggedUserName);
          // this.sService.setPw(this.isPassowordSet);
          // this.router.navigate(['/Home']);
          const files = this.myFilesService.getFiles();
          this.myFilesService.setMyFiles(files);
          this.loginSuccessfulChange.emit(true);

          this.close();

          this.savedStoryService.getIds();

          // const toast: Toast = {
          //   type: 'success',
          //   body: 'Logged in successfully!',
          //   showCloseButton: true,
          //   closeHtml: '<div class="toaster-close-icon"></div>'
          //
          // };
          // this.toasterService.pop(toast);
          // setTimeout(() => {
          //   this.toasterService.clear(toast.toastId, toast.toastContainerId);
          // }, 2000)
        },
          (error) => {
            this.loader = false;
            //
          });
    }
  }

  close() {
    this.loginPopupChange.emit(false);
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgotpassword');
  }

}
