import { McService } from "../../shared/services/mc-service";
import { ContactModel } from "./contact-model";
import { MapsAPILoader } from "@agm/core";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { Router } from "@angular/router";
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";

declare var google: any;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.less"],
})
export class ContactComponent implements OnInit {
  london = true;
  newYork = false;
  loader: boolean;
  lat: number;
  lng: number = 7.809007;
  lata: number;
  lnga: number;
  latf: number;
  lngf: number;
  lats: number;
  lngs: number;
  address: any;
  address2: any;
  zoom = 14;
  locationCity: string;
  message = false;
  mailSent: boolean;
  styles = new PageStyle();
  valForm: FormGroup;
  language: Language;

  mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#e7f3d5",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#f8edf1",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#e7f3d5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#d7e9b7",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#eaeaea",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#eaf7fa",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  contactUsResponse = new ContactModel();

  constructor(
    private mcService: McService,
    private mapsAPILoader: MapsAPILoader,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private router: Router,
    private fontsAndColorsService: FontsAndColorsService
  ) {
    this.valForm = this.fb.group({
      firstname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      lastname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      telephone: [null],
      company: [null],
      email: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.email,
          Validators.maxLength(50),
        ]),
      ],
      message: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(500)]),
      ],
    });

    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  ngOnInit() {
    this.loader = true;
    this.getContactUs();

    this.valForm.controls["firstname"].setValidators(Validators.required);
    this.valForm.controls["lastname"].setValidators(Validators.required);
    this.valForm.controls["message"].setValidators(Validators.required);
    window.scrollTo(0, 0);
  }

  geocodeLatLng(geocoder, lat, lng) {
    let latlng = { lng: lng, lat: lat };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          for (let i = 0; results[0].address_components.length > i; i++) {
            if (results[0].address_components[i].types[0] === "route") {
              this.address = results[2].formatted_address;
            }
          }
        } else {
        }
      } else {
      }
    });
  }

  geocodeLatLng2(geocoder, lat, lng) {
    let latlng = { lng: lng, lat: lat };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          for (let i = 0; results[0].address_components.length > i; i++) {
            if (results[0].address_components[i].types[0] === "street_number") {
              this.address2 = results[0].address_components[i].long_name;
            }
            if (results[0].address_components[i].types[0] === "route") {
              this.address2 += " " + results[0].address_components[i].long_name;
            }

            if (results[0].address_components[i].types[0] === "locality") {
              this.address2 +=
                ", " + results[0].address_components[i].long_name;
            }
            if (
              results[0].address_components[i].types[0] ===
              "administrative_area_level_1"
            ) {
              this.address2 +=
                ", " + results[0].address_components[i].short_name;
            }
            if (results[0].address_components[i].types[0] === "postal_code") {
              this.address2 += " " + results[0].address_components[i].long_name;
            }
          }
        } else {
        }
      } else {
      }
    });
  }

  getMap() {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      this.geocodeLatLng(geocoder, this.latf, this.lngf);
      this.geocodeLatLng2(geocoder, this.lats, this.lngs);
    });
  }

  getContactUs() {
    this.loader = true;
    this.mcService.getContactUs(1).subscribe(
      (response: any) => {
        this.contactUsResponse = response;
        this.loader = false;
        this.latf = response.latitude;
        this.lngf = response.longitude;
        this.lnga = this.lngf;
        this.lata = this.latf;
        this.lats = response.latitude2;
        this.lngs = response.longitude2;
        this.getMap();
      },
      (error) => {
        //
        this.loader = false;
      }
    );
  }

  sendEmail() {
    this.message = false;
    this.mailSent = true;
    this.mcService.sendEmail(this.contactUsResponse.emailModel).subscribe(
      (response) => {
        this.message = true;
        this.mailSent = false;
        this.valForm.reset();
      },
      (error) => {}
    );
  }

  switchLondon() {
    this.london = true;
    this.newYork = false;
  }

  switchNewYork() {
    this.london = false;
    this.newYork = true;
  }

  changeCord(lng, lat, sity) {
    this.lnga = lng;
    this.lata = lat;
    if (sity == "london") {
      this.london = true;
      this.newYork = false;
    } else {
      this.newYork = true;
      this.london = false;
    }
  }

  onSubmit(e) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.sendEmail();
    }
  }
}
