<section class="story-detail flex-center">
  <div class="story-header flex-center story-detail-v2">
    <div class="header-wrapper flex-center story-detail-v2">
      <div class="story-featured-image story-detail-v2">
        <img class="featured-image story-detail-v2" src="assets/files/Wright.jpg" alt="Airplane" />
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="story-text">
      <h1 class="story-headline" [ngStyle]="{
          color: styles.style ? styles.style.textH2Color : '',
          'font-family': styles.style.fonts.sliderHeadlineFont
        }">
        {{ title }}
      </h1>

      <div id="bodytext" [ngStyle]="{
          color: styles.style ? styles.style.textMainColor : '',
          'font-family': styles.style.fonts.paragraphFont
        }">
        <p>
          We’re on the lookout for talented young designers to enter our
          competition to design a sustainable easyJet aircraft of the future!
        </p>
        <div class="video-slider-container">
          <div class="current-video-container">
            <video class="current-video" loop [controls]="true" [muted]="true">
              <source src="assets\files\video.mp4" type="video/mp4" />
              {{ language.unsupportedVideoBrowser }}
            </video>
          </div>
        </div>

        <p>
          We’re calling on school children up and down the UK – like you – to
          join our Aircraft of the Future competition to design a passenger
          plane for travelling across Europe, powered by a sustainable energy
          source.
        </p>
        <p>
          We’re supporting the development of new zero-emission technologies for
          planes – and we want you to feel inspired to become part of the
          revolution of future air travel as zero-emission aircraft designers
          and engineers.
        </p>
        <p>
          Your design will be judged by a panel of world-class experts in
          flying, sustainability, design, and zero-emission technology:
        </p>
        <ul>
          <li>
            easyJet’s very own First Officer and zero-emission aircraft modeller
            for FlyZero - Debbie Thomas
          </li>
          <li>easyJet’s Director of Sustainability, Jane Ashton</li>
          <li>Airbus’ VP of Zero-Emission Aircraft Glenn Llewellyn</li>
          <li>Wright Electric CEO Jeff Engler</li>
          <li>
            World-renowned designer Frank Stephenson, known for his innovative
            work on designs for electric flying taxis as well as his iconic car
            designs for the new MINI and Fiat 500.
          </li>
        </ul>
        <p class="body-title">How to Enter</p>
        <p>
          Your entry should feature a sketch drawn on paper or a tablet complete
          with notes and descriptions, highlighting how your aircraft works and
          why you have chosen the elements of your design. Take a photo of your
          drawing or save your digital sketch and submit it to us using the form
          below.
        </p>
        <p>
          The winning design will illustrate originality, style, innovation, and
          creativity and most of all focus on how to make the plane as
          <span style="font-weight: bold">sustainable as possible!</span>
        </p>
        <p class="body-title">Some tips from our judges:</p>
        <p>
          Your design should consider both technical and creative elements and
          the reasons for including them, such as:
        </p>
        <ul>
          <li>What makes it environmentally friendly and sustainable</li>
          <li>The materials used for the plane and the cabin inside</li>
          <li>
            What will power the aircraft and what other parts will make it fly
          </li>
          <li>How it will take off and land</li>
          <li>Shape of the plane and aerodynamics</li>
          <li>
            How all passengers get on and off the plane quickly and comfortably
          </li>
          <li>
            What will make the flight time really enjoyable for passengers on
            board
          </li>
          <li>
            How many passengers will it fly and what services will be available
            for them
          </li>
          <li>
            The patterns, shapes, and colours on the outside and inside of the
            aircraft to make it stand out
          </li>
        </ul>
        <p class="body-title">The prizes</p>
        <p>
          The winning entry will receive a money-can’t-buy prize in the form of
          a trip to the easyJet Training Centre in London Gatwick for an
          experience in a full-flight simulator, plus return easyJet flights to
          anywhere on the airline’s network for the winner’s immediate family,
          as well as a 3-D model and rendering of their design, created and
          signed by Frank Stephenson.
        </p>
        <p>
          In addition, the winner of the 12-16-year age category will also win a
          behind the scenes visit to Airbus as well as a chance to feature on
          Frank Stephenson’s
          <a href="https://www.frankstephenson.com/category/podcasts/">podcast</a>
          to discuss their design.
        </p>
        <p>
          Runners up will also be in with a chance to receive a rendering of
          their design, created and signed by Frank Stephenson.
        </p>
        <p class="body-title">The rules</p>
        <ul>
          <li>
            We must receive your entry for the competition before midnight
            (23:59 UK local time) on Sunday 31 October 2021.
          </li>
          <li>
            All entrants must be between the ages of 7 and 16 and should ask
            permission from a parent or guardian before entering.
          </li>
          <li>
            Please don’t post your artwork to us, we’re only accepting online
            entries and we’ll be unable to return any artwork sent in by post or
            shared with anyone at easyJet.
          </li>
          <li>
            When taking a photo of your artwork to send to us, please make sure
            you use natural lighting. Dark or unnatural light can result in
            distorted image colour/ appearance.
          </li>
          <li>
            Winners must be available to attend a full-day video and photography
            shoot, accompanied by a parent or Guardian at London Luton Airport
            on Wednesday 10th November.
          </li>
        </ul>

        <div class="icon-section-container">
          <div class="icon-section-icon"></div>
          <div class="icon-section-text">
            <p>
              “We know the environment is important to all of our customers,
              including our younger passengers, so we are really excited to
              launch our Plane Amazing competition to engage younger generations
              in the exciting possibilities of new zero-emissions technology for
              air travel and inspire them to become designers and engineers of
              the future, who will play a vital role in the industry’s future.
            </p>
            <p>
              easyJet First Officer and zero-emission aircraft modeller for
              FlyZero - Debbie Thomas
            </p>
          </div>
        </div>

        <p class="body-title">Judges:</p>
        <div class="judge-section">
          <img src="assets/files/DebbieHead.jpg" class="judge-image" alt="Judge Debbie" />
          <p class="body-title">
            Debbie Thomas – easyJet First Officer and Zero Emission Aircraft
            Modeller
          </p>
          <p>
            Debbie Thomas is a pilot for easyJet who has significant experience
            working across the aviation and aerospace sectors. She is also an
            experienced engineer and zero-emission aircraft modeller for FlyZero
            who are studying the design of potential new zero-carbon emission
            aircraft.
          </p>
        </div>
        <div class="judge-section">
          <img src="assets/files/Jane.jpg" class="judge-image" alt="Judge Jane" />
          <p class="body-title">
            Jane Ashton – Director of Sustainability, easyJet
          </p>
          <p>
            Jane Ashton is responsible for developing and implementing the
            sustainability strategy across easyJet and easyJet holidays. Jane’s
            goal is to drive change for sustainable travel and tourism.
          </p>
        </div>
        <div class="judge-section">
          <img src="assets/files/Glenn.jpg" class="judge-image" alt="Judge Glenn" />
          <p class="body-title">
            Glenn Llewellyn – Vice President Zero-Emission Aircraft, Airbus
          </p>
          <p>
            Glenn is at the helm of a zero-emission revolution at Airbus with
            the mission to unite all the ingredients needed to launch the
            world’s first zero-emission commercial aircraft.
          </p>
        </div>
        <div class="judge-section">
          <img src="assets/files/FrankHead.jpg" class="judge-image" alt="Judge Frank" />
          <p class="body-title">
            Frank Stephenson – Automotive Transportation Designer
          </p>
          <p>
            Frank Stephenson is a world-famous automotive transportation
            designer who is best known for his iconic car designs for MINI and
            Fiat 500, as well as recent innovative work designing electric
            flying vehicles.
          </p>
        </div>
        <div class="judge-section">
          <img src="assets/files/Jeff.jpg" class="judge-image" alt="Judge Jeff" />
          <p class="body-title">Jeff Engler</p>
          <p>
            Jeff Engler is an entrepreneur and co-founder of Wright Electric, an
            aviation company developing electric airplanes for short regional
            flights. He has a BA in history from Columbia College and an MBA
            from Harvard Business School.
          </p>
        </div>

        <!-- <p class="body-title">
          CLOSING DATE IS BEFORE MIDNIGHT (23:59 UK LOCAL TIME) ON SUNDAY 31
          OCTOBER 2021.
        </p> -->

        <p class="body-title">
          We’re very sorry that entries aren’t available right now. Please try
          again soon. We apologise for any inconvenience.
        </p>
        <p class="body-title">
          COMPETITION IS CLOSED.
        </p>

        <!-- <div class="submission-container">
          <div class="submission-title">Submit here</div>
          <form action="" class="register-form flex" [formGroup]="valForm">
            <div class="form-element flex">
              <label
                for="name"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >{{ language.firstName }}</label
              >
              <input
                type="text"
                id="name"
                placeholder="Enter your first name"
                [(ngModel)]="submissionModel.firstName"
                formControlName="firstname"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <span
                class="text-danger"
                *ngIf="
                  valForm.controls['firstname'].hasError('required') &&
                  (valForm.controls['firstname'].dirty ||
                    valForm.controls['firstname'].touched)
                "
              >
                {{ language.firstNameRequired }}</span
              >
            </div>
            <div class="form-element flex">
              <label
                for="lastname"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >{{ language.lastName }}</label
              >
              <input
                type="text"
                id="lastname"
                placeholder="Enter your last name"
                [(ngModel)]="submissionModel.lastName"
                formControlName="lastname"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <span
                class="text-danger"
                *ngIf="
                  valForm.controls['lastname'].hasError('required') &&
                  (valForm.controls['lastname'].dirty ||
                    valForm.controls['lastname'].touched)
                "
              >
                {{ language.lastNameRequired }}</span
              >
            </div>
            <div class="form-element flex">
              <label
                for="email"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >{{ language.email }}</label
              >
              <input
                type="text"
                id="email"
                placeholder="{{language.enterEmail}}"
                [(ngModel)]="submissionModel.email"
                (keyup)="checkIfEmpty()"
                [formControl]="email"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <span
                class="text-danger"
                *ngIf="
                  email.hasError('required') && (email.dirty || email.touched)
                "
              >
                E{{ language.emailNameRequired }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  email.hasError('email') && (email.dirty || email.touched)
                "
                >{{ language.invalidEmail }}</span
              >
            </div>
            <div class="form-element flex">
              <label
                for="phone"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >{{ language.telephone }}</label
              >
              <input
                type="text"
                id="phone"
                placeholder="Enter your phone number"
                [(ngModel)]="submissionModel.phone"
                formControlName="phone"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <span
                class="text-danger"
                *ngIf="
                  valForm.controls['phone'].hasError('required') &&
                  (valForm.controls['phone'].dirty ||
                    valForm.controls['phone'].touched)
                "
              >
                A phone number is required</span
              >
            </div>
            <div class="form-element flex">
              <label
                for="age"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >Entrant's Age</label
              >
              <input
                type="number"
                id="age"
                placeholder="Enter the entrant's age"
                [(ngModel)]="submissionModel.age"
                formControlName="age"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <span
                class="text-danger"
                *ngIf="
                  valForm.controls['age'].hasError('required') &&
                  (valForm.controls['age'].dirty ||
                    valForm.controls['age'].touched)
                "
              >
                An age must be specified</span
              >
            </div>
            <div class="form-element flex">
              <label [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >Category</label
              >
              <div
                class="select bigger register-dropdown flex-center"
                (click)="categoryDropdown = !categoryDropdown"
                appClickOutside
                (clickOutside)="onClickOutside($event)"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              >
                <div class="flex select-list">
                  <div>Primary School (7 - 11)</div>
                  <div>Secondary School (12 - 16)</div>
                </div>
                <div
                  class="language-dropdown flex flex-column"
                  *ngIf="categoryDropdown"
                >
                  <div
                    class="choice flex"
                    *ngFor="let cat of categories"
                    (click)="
                      $event.stopPropagation(); selectCategory(cat, $event)
                    "
                  >
                    <input
                      type="checkbox"
                      [checked]="cat.checked"
                      [ngStyle]="{
                        background: cat.checked
                          ? styles.style?.mainIdentifyingColor
                          : ''
                      }"
                    />
                    <div
                      (click)="$event.stopPropagation(); selectCategory(cat)"
                    >
                      {{ cat.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-element flex file-form-element">
              <label
                for="file"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >{{
                  this.submissionModel.submissionImage
                    ? this.submissionModel.submissionImage
                    : "Upload"
                }}</label
              >
              <img
                *ngIf="true"
                class="w130 hidden"
                id="submissionImg"
                [src]="submissionImage"
              />
              <input
                type="file"
                class="file-upload"
                id="file"
                (change)="uploadSubmissionImage($event)"
                placeholder="Upload your file"
                [(ngModel)]="submissionModel.file"
                formControlName="file"
                [ngStyle]="{
                  'font-family': styles.style.fonts.placeholderFont
                }"
              />
              <p *ngIf="imageerrorSubmission" class="red">
                Image must be at least 385 x 327
              </p>
              <span class="text-danger" *ngIf="noFile">
                You must upload a submission file
              </span>
            </div>
            <div class="form-element tnc-form-element">
              <label
                for="terms"
                [ngStyle]="{ 'font-family': styles.style.fonts.labelFont }"
                >Terms and Conditions</label
              >
              <div class="terms-subtitle">
                Please confirm you have read and understood the Terms and
                Conditions
              </div>
              <div class="flex terms-checkbox">
                <input
                  type="checkbox"
                  (click)="termsCheck($event); $event.stopPropagation()"
                  [checked]="termsAndConditions"
                  formControlName="terms"
                />
              </div>
              <span
                class="text-danger"
                *ngIf="
                  (valForm.controls['terms'].dirty ||
                    valForm.controls['terms'].touched) &&
                  !termsAndConditions
                "
              >
                You must read the Terms and Conditions first
              </span>
            </div>
            <div class="register-buttons flex-center">
              <button
                (click)="onSubmit($event)"
                class="generic-button submit flex-center"
                [ngStyle]="{
                  background: styles.style?.mainIdentifyingColor,
                  'font-family': styles.style.fonts.btnFont
                }"
              >
                {{ "Submit" }}
              </button>
            </div>
          </form>
        </div> -->

        <!-- <div class="terms-and-conditions-container">
          <div class="terms-and-conditions-title">Terms and Conditions</div>
          <iframe
          class="terms-and-conditions-i"
            src="https://docs.google.com/document/d/e/2PACX-1vSY4KLMsNueGuOZzbW-IQeXgKgzpoF0omIO6J54MOLrmY4pZb7jFUot7T8yUcKGDEGOHeFIVep1zhm8/pub?embedded=true"
          ></iframe>
        </div> -->
      </div>
    </div>
  </div>
</section>

<app-loader *ngIf="loader"></app-loader>