import { Directive,HostListener,ElementRef, Input,OnInit } from '@angular/core';

@Directive({
  selector: '[appStyle]'
})
export class StyleDirective implements OnInit{

  @Input() defaultColor:string;
  @Input() hoverColor:string;
  @Input() borderColor:string;
  @Input() color:string;


  constructor(private ElementRef:ElementRef) { }

  ngOnInit(){
    if(this.defaultColor!=null){
      this.ElementRef.nativeElement.style.backgroundColor=this.defaultColor;
    }
    if(this.borderColor!=null){
      this.ElementRef.nativeElement.style.borderColor=this.borderColor;
    }
    if(this.color!=null){
      this.ElementRef.nativeElement.style.color=this.color;
    }
  }

  @HostListener('mouseenter') mouseenter(eventData:Event){
    if(this.hoverColor!=null){
      this.ElementRef.nativeElement.style.backgroundColor=this.hoverColor;
    }
  }

  @HostListener('mouseleave') mouseleave(eventData:Event){
    if(this.defaultColor!=null){
      this.ElementRef.nativeElement.style.backgroundColor=this.defaultColor;
    }
  }

}
