import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from '../../shared/services/http-request.service';

@Injectable()
export class AboutService {

  constructor(private http: HttpRequestService) {
  }

  private mediaCenterWebAboutUsUrl = environment.apiUrl + '/MediaCenterWeb/MediaCenterWebAboutUs';

  mediaCenterWebAboutUs() {
    return this.http.get(this.mediaCenterWebAboutUsUrl);
  }

}
