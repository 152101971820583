import { Component, OnInit } from '@angular/core';
import { TokenManagerService } from '../../shared/services/token-manager.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PageStyle } from '../../models/style/style.model';
import { FontsAndColorsService } from '../../shared/services/fonts-and-colors.service';
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { StorageService } from '../../shared/services/storage.service';
import { SavedStoryService } from '../../shared/services/saved-story.service';
import { ToastrService } from 'ngx-toastr';
import { McService } from '../../shared/services/mc-service';
import { Language } from "../../models/language/language.model";
import { LanguageService } from "../../shared/services/language.service";

@Component({
  selector: 'app-unsubscribe-newsletter',
  templateUrl: './unsubscribe-newsletter.component.html',
  styleUrls: ['./unsubscribe-newsletter.component.less']
})
export class UnsubscribeNewsletterComponent implements OnInit {
  unsubscribeCode: string;
  loading: boolean = false;
  styles: PageStyle;
  buttonsHelper = ButtonsHelper;
  language: Language;

  constructor(
    private authLoginService: TokenManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fontsAndColorsService: FontsAndColorsService,
    private sService: StorageService,
    private savedStoryService: SavedStoryService,
    private toastr: ToastrService,
    private mcService: McService,
    private languageService: LanguageService,
  ) {
    this.styles = this.fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.unsubscribeCode = params["code"];
    });
  }

  unsubscribe() {
    this.loading = true;
    this.mcService.unsubscribeFromNewsletter(this.unsubscribeCode).subscribe(
      () => {
        this.toastr.success("You have successfully unsubscribed from our newsletter.");
        this.router.navigate(['/']);
        this.loading = false;
      },
      (error: any) => {
        this.toastr.error("Something went wrong. Please try again later.");
        this.router.navigate(['/']);
      }
    );
  }

  cancel() {
    this.router.navigate(['/']);
  }

}
