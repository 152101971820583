import { Injectable, Injector } from "@angular/core";
import { TokenManagerService } from "./token-manager.service";
import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthInterceptorService {
  /*constructor(private auth: TokenManagerService) {
  }*/

  constructor() {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //
    //

    //const auth: any = this.inj.get(TokenManagerService);
    //Don't add Authorization to media blob request
    if (req.url.indexOf(environment.mediaUrl) > -1) {
      return next.handle(req);
    }

    // Get the auth header from the service.
    let authHeader = TokenManagerService.getToken();
    if (!authHeader) authHeader = "";

    // Clone the request to add the new header.
    const authReq = req.clone({
      headers: req.headers.set("Authorization", authHeader),
    });
    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}
