<section class="login flex">
  <div class="register-wrapper">
    <h1 *ngIf="isAuthenticated == true" class="headline">Success</h1>
    <h1 *ngIf="isAuthenticated == false" class="headline">Error</h1>
    <div *ngIf="isAuthenticated == true" class="form-element flex"><p>You have verified your account. We will redirect you to home page in {{countDown | async}} or you can click<i class="redirect"
      (click)="redirect()"> here</i>.</p></div>

    <div *ngIf="isAuthenticated == false" class="form-element flex"><p>You have already verified your account.</p></div>
  </div>
</section>

