export class LayoutModel {
  public clientAltLogo: string;
  public clientLogo: string;
  public headerLinks: HeaderLinksModel[];
  public footerLinks: FooterLinksModel[];
  public hasBrands: boolean;
  public loginWithFacebook: boolean;
  public loginWithTwitter: boolean;
  public disableRegistration: boolean;
  public languages: any[];
  public cookieNotice: string;
  public defaultLanguageId: number;
  public showFooterSocialLinks: boolean;
  public showHeaderSocialLinks: boolean;
  public socialLinkJson: string;
  public socialLinks: string;
  public footerText: string;

  constructor() { }
}

export class HeaderLinksModel {
  public link: string;
  public url: string;

  //added new properties
  public active: boolean;

  constructor() { }
}

export class FooterLinksModel {
  public link: string;
  public url: string;

  //added new properties
  public active: boolean;
}

