import { Component, Input, OnInit } from "@angular/core";
import { WebNewsDetailsMediaModel } from "../../news/news-model";

declare var $: any;

@Component({
  selector: "app-media-group",
  templateUrl: "./media-group.component.html",
  styleUrls: ["./media-group.component.less"],
})
export class MediaGroupComponent implements OnInit {
  @Input() group: WebNewsDetailsMediaModel[];
  @Input() config: any;
  @Input() mirrored: boolean;

  constructor() {
  }

  ngOnInit() {}
}
