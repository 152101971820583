import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { PageStyle } from '../../../models/style/style.model';
import { FontsAndColorsService } from '../../services/fonts-and-colors.service';
import { Language } from "../../../models/language/language.model";
import { LanguageService } from "../../services/language.service";

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.less']
})
export class RegisterPopupComponent implements OnInit {
  @Input() registerPopup: boolean;
  styles = new PageStyle();
  language: Language;

  @Output() registerPopupClose = new EventEmitter<boolean>();
  constructor(private router: Router, public fontsAndColorsService: FontsAndColorsService, private languageService: LanguageService,
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();
  }

  ngOnInit() {
  }

  close() {
    this.registerPopupClose.emit(false);
    this.router.navigateByUrl('/sign-in', { replaceUrl: true });
  }

}
