import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { McService } from '../../services/mc-service';
import { TermsModel } from '../../../routes/terms/terms-model';
import { Language } from "../../../models/language/language.model";
import { LanguageService } from "../../services/language.service";

@Component({
  selector: 'app-terms-popup',
  templateUrl: './terms-popup.component.html',
  styleUrls: ['./terms-popup.component.less']
})
export class TermsPopupComponent implements OnInit {
  @Input() termsPopup: boolean;
  @Output() termsPopupChange = new EventEmitter<boolean>();
  loader: boolean;
  termsResponseModel = new TermsModel();
  language: Language;

  constructor(private mcService: McService, private router: Router, private languageService: LanguageService,
  ) {
    this.language = languageService.getLanguage();
  }

  ngOnInit() {
    this.getTerms();
    window.scrollTo(0, 0);

  }

  getTerms() {
    this.loader = true;
    this.mcService.getTerms()
      .subscribe((response: any) => {
        this.termsResponseModel = response;
        this.loader = false;
        // if (this.termsResponseModel.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   this.router.navigateByUrl('/register');
        // }
      },
        (error) => {
          //
        });
  }

  close() {
    this.termsPopupChange.emit(false);
  }

}
