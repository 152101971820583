<section class="brands flex-center">
  <h1 class="hidden-h1">Brands</h1>
  <div class="content-wrapper" [style.width.px]="[
      brandsResponse.mcType == 'HasRelatedCenters' ? '' : ''
    ]" [ngClass]="[
      brandsResponse.mcType == 'HasRelatedCenters'
        ? 'content-wrapper-related-centers'
        : ''
    ]">
    <div class="brand-feed">
      <div *ngIf="brandsResponse.mcType != 'HasRelatedCenters'" class="brand-bar flex-center">
        <h2 class="section-headline" [ngStyle]="{ 'font-family': styles.style.fonts.sectionHeadlineFont }">
          {{ language.brands }}
        </h2>
        <div class="brand-search flex">
          <input [(ngModel)]="query" placeholder="{{ language.search }}"
            [ngStyle]="{ 'font-family': styles.style.fonts.placeholderFont }" />
          <div class="form-item flex" [ngStyle]="{ 'font-family': styles.style.fonts.placeholderFont }">
            <app-dropdown [dropdownLabel]="categoryLabel" (filtered)="filter($event)" [sourceList]="
                brandsResponse.customCategories
                  ? brandsResponse.customCategories
                  : brandsResponse.mainCategories
              "></app-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="brandsResponse.mcType != 'HasRelatedCenters'" class="brand-wrapper flex">
        <div class="brand-box" *ngFor="let brand of visibleBrands | search : 'name' : query"
          (click)="brandStories(brand.name)">
          <div class="brand-image flex-center">
            <img src="{{ brand.logoUrl }}" alt="{{ brand.name }} Logo" />
          </div>
          <div class="brand-name flex-center" [ngStyle]="{
              'font-family': styles.style.fonts.brandNameFont,
              'text-align': 'center'
            }">
            {{ brand.name }}
          </div>
        </div>
        <div class="no-result" *ngIf="searchResult" [ngStyle]="{ 'font-family': styles.style.fonts.footerText }">
          {{ language.noBrandsInCustomCategory }}
        </div>
      </div>
      <div *ngIf="brandsResponse.mcType == 'HasRelatedCenters'" class="brand-wrapper related-centers-wrapper flex">
        <div class="brand-box" *ngFor="let brand of visibleBrands | search : 'name' : query"
          (mouseover)="brand.brandHovered = true" (mouseleave)="brand.brandHovered = false"
          (click)="goToMc(brand.mediaCenterUrl, brand.id)">
          <div class="brand-image flex-center">
            <img src="{{ brand.logoUrl }}" alt="{{ brand.name }} Logo" />
          </div>
          <div [ngClass]="[brand.brandHovered ? 'show-name' : '']" [ngStyle]="{
              'font-family': styles.style.fonts.brandNameFont,
              'text-align': 'center'
            }" class="brand-name flex-center">
            {{ brand.name }}
          </div>
        </div>
        <div class="no-result" *ngIf="searchResult" [ngStyle]="{ 'font-family': styles.style.fonts.footerText }">
          {{ language.noBrandsInCustomCategory }}
        </div>
      </div>
    </div>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>