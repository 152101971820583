import {Injectable} from "@angular/core";
@Injectable()
export class ErrorService {


  private error: any;

  setError(error) {
    this.error = error;
  }

  getError() {
    return this.error;
  }
}
