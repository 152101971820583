import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less']
})
export class DropdownComponent implements OnInit {
  @Input() dropdownLabel: string;
  @ViewChild ('dropDownList') dropDownList: ElementRef;
  @ViewChild ('dropdownPop') dropdownPop: ElementRef;
  @Output() filtered = new EventEmitter();
  @Input() dropdownSize: string;
  @Input() dropdownStyle: boolean;
  @Input() sourceList: any;

  dropdown = false;

  constructor() { }

  ngOnInit() {

  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.dropdown = false;
    }
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  changeLabel(s) {
    this.dropdownLabel = s.label;
    this.filtered.emit(s);
    // this.filtered = this.sourceList.filter(x => x.id === s.id);

  }
}
