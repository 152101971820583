<section class="login flex">
  <div class="register-wrapper">
    <h1 class="headline">Register DNA 3 User</h1>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="reset($event, data)">
      <div class="form-element flex">
        <label for="new-password">{{ language.password }}</label>
        <input type="password" id="new-password" name="new-password" placeholder="Enter your new password"
          [(ngModel)]="data.newPassword" formControlName="new-password" />
        <span class="text-danger" *ngIf="
            valForm.controls['new-password'].hasError('required') &&
            (valForm.controls['new-password'].dirty ||
              valForm.controls['new-password'].touched)
          ">
          New password is required
        </span>
        <span class="text-danger" *ngIf="
            valForm.controls['new-password'].hasError('pattern') &&
            (valForm.controls['new-password'].dirty ||
              valForm.controls['new-password'].touched)
          ">
          {{ language.passwordNoSpaces }}</span>
      </div>
      <div class="form-element flex">
        <label for="confirmPassword">{{ language.confirmPassword }}</label>
        <input type="password" name="confirmPassword" id="confirmPassword" [placeholder]="language.confirmPassword"
          [ngClass]="[confirmError ? 'ng-invalid ng-touched' : '']" (keyup)="checkPassword()"
          [(ngModel)]="data.confirmPassword" formControlName="confirmpassword" />
        <span class="text-danger" *ngIf="
            valForm.controls['confirmpassword'].hasError('required') &&
            (valForm.controls['confirmpassword'].dirty ||
              valForm.controls['confirmpassword'].touched)
          ">
          {{ language.confirmPasswordRequired }}
        </span>
        <span class="text-danger" *ngIf="
            valForm.controls['confirmpassword'].value !=
              valForm.controls['new-password'].value &&
            !valForm.controls['confirmpassword'].hasError('required') &&
            valForm.controls['new-password'].value != ''
          ">{{ language.passwordsNoMatch }}</span>
      </div>
      <div class="register-buttons flex-center">
        <button [ngStyle]="{ background: styles.style?.mainIdentifyingColor }" type="submit" class="submit flex-center">
          {{ language.submit }}
        </button>
      </div>
    </form>
  </div>
</section>