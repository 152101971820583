import { Component, OnInit } from '@angular/core';
import { AboutService } from './about-us.service';
import { AboutUsModel } from './about-us.model';
import { Meta } from '@angular/platform-browser';
import { McService } from '../../shared/services/mc-service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageStyle } from '../../models/style/style.model';
import { FontsAndColorsService } from '../../shared/services/fonts-and-colors.service';
import { MetaModel } from '../../models/meta/meta';
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../models/language/language.model';
import { BrandsManageModel, BrandsModel } from '../brands/brands-model';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less'],
  providers: [AboutService]
})
export class AboutUsComponent implements OnInit {
  about = new AboutUsModel();
  loader: boolean;
  styles = new PageStyle();
  metadatas: MetaModel;
  language: Language;
  brandsResponse = new BrandsModel();
  brands: BrandsManageModel[] = [];

  constructor(private AboutService: AboutService, private languageService: LanguageService, private meta: Meta, private titleService: Title, private router: Router,
    private mcService: McService, private fontsAndColorsService: FontsAndColorsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getAbout();
    this.getBrands();
  }

  getAbout() {
    this.loader = true;
    this.AboutService.mediaCenterWebAboutUs()

      .subscribe((response: any) => {
        this.about = response;
        
        this.loader = false;
      },
        (error) => {
          //
        });
  }


  getBrands() {
    this.loader = true;
    this.mcService.getBrands()
      .subscribe((response: any) => {
        this.brandsResponse = response;
        // if (this.brandsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
        //   this.router.navigateByUrl('/sign-in');
        // }
        this.brands = response.clients;
        this.loader = false;
        if (this.brandsResponse.customCategories && this.brandsResponse.customCategories.length > 0)
          this.brandsResponse.customCategories.unshift({ id: 0, parentId: 0, label: 'All' });
        if (this.brandsResponse.mainCategories && this.brandsResponse.mainCategories.length > 0)
          this.brandsResponse.mainCategories.unshift({ id: 0, parentId: 0, label: 'All' });
      },
        (error) => {
          //
        });
  }
}
