export class TestimonialsViewModel {
  public testimonials: TestimonialsModel[];
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
}

export class TestimonialsModel{
  public imageUrl: string;
  public name: string;
  public clientLogoUrl: string;
  public text: string;
  public date: string;
}
