import {Output, Pipe, PipeTransform} from '@angular/core';
import {StorageService} from '../services/storage.service';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  constructor(private sService: StorageService){

  }
  public transform(value, keys: string, term: string) {
    if (!term) {
      return value;
    }
    if((value || []).filter((item) => keys.split(',')
      .some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi')
        .test(item[key]))).length === 0){
      this.sService.setSearch(true)
    }
    else{
      this.sService.setSearch(false);
    }
    return (value || []).filter((item) => keys.split(',')
      .some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi')
        .test(item[key])));
  }
}
