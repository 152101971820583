<section class="about-us">
  <div class="about-us-image">
    <img *ngIf="about.aboutUsHeroImageUrl" [src]="about.aboutUsHeroImageUrl" alt="About us Hero Image">
    <div class="title-container">
      <h1>{{about.aboutUsH1Text}}</h1>
      <h2>{{about.aboutUsH2Text}}</h2>
      <p>{{about.aboutUsH2Text}}</p>
    </div>
  </div>
  <div class="about-us-content">
    <div class="about-us-text">

      <p [innerHTML]="about.aboutUsText"></p>

    </div>

    <div class="about-us-links" *ngIf="about.aboutUsQuoteText">
      <div class="about-us-quote">
        <div class="background"></div>
        <div class="quote start">“</div>
        <p class="quote-text" [innerHTML]="about.aboutUsQuoteText"></p>
        <div class="quote end">“</div>
        <span>{{about.aboutUsSignature}}</span>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
</section>