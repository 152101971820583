import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { MyFilesService } from "../../shared/services/my-files.service";
import { DownloadModel } from "../../download/download-model";
import { McService } from "../../shared/services/mc-service";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { DownloadAddToModel } from "../../routes/media/media-model";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { HttpClient } from "@angular/common/http";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { Language } from "../../models/language/language.model";
import { LanguageService } from "../../shared/services/language.service";
import { DownloadBoxService } from "../../shared/services/download-box.service";
import { NumberSymbol } from "@angular/common";
import { StringDecoder } from "string_decoder";
import { WebpHelperService } from "../../shared/services/webp-helper.service";

@Component({
  selector: "app-my-files",
  templateUrl: "./my-files.component.html",
  styleUrls: ["./my-files.component.less"],
})
export class MyFilesComponent implements OnInit {
  @Output()
  clickedOutsideEvent: EventEmitter<string> = new EventEmitter<string>();
  styles: PageStyle;
  buttonsHelper = ButtonsHelper;
  cart: any = [];
  downloadMediaResponse: DownloadModel;
  showLoginMessage: boolean = false;
  loader: boolean = false;
  downloadUrls: string[] = [];
  downloadActive: boolean = false;
  cancelDownloads: boolean;
  user: any;
  downloadSubscription: any;
  shareCartUrl: string;
  language: Language;
  copiedUrl: String = "";

  constructor(
    private fontsAndColorsService: FontsAndColorsService,
    private myFilesService: MyFilesService,
    private mcService: McService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private languageService: LanguageService,
    private http: HttpClient,
    private eRef: ElementRef,
    private downloadBoxService: DownloadBoxService,
    public webPHelper: WebpHelperService
  ) {
    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.myFilesService.files.subscribe((myFiles) => {
      this.cart = myFiles;
      this.getDownloadBox();
    });
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      !(
        event.target.className == "my-files-container" ||
        event.target.className == "my-files-text" ||
        event.target.className == "far fa-folder" ||
        event.target.className == "my-files-icon"
      )
    ) {
      this.clickedOutsideEvent.emit(event);
    }
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.cart = this.myFilesService.getMyFiles();
    this.getDownloadBox();
  }

  deleteAll() {
    this.myFilesService.clearFiles();
  }

  deleteFile(mediaId) {
    this.myFilesService.deleteFile(mediaId);
  }

  shareCart() {
    this.shareCartUrl = `${UrlHelper.getCurrentUrlHostOnly()}/download/share/${localStorage.getItem(
      "cart"
    )}`;
  }

  cancelDownload() {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
    this.downloadActive = false;
    this.cancelDownloads = true;
  }

  downloadFinishEvent(downloadFiles) {
    let user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      user.id = null;
    }
    let timeNow = Date.now();
    let formattedTime = new Date(timeNow).toUTCString();
    this.googleAnalytics.emitEvent('Download Box', 'Downloads', 'Download box', `User: ${user.id} | Time: ${formattedTime} | Files: ${downloadFiles}`);
  }

  download() {
    let startTime = performance.now();
    const component = this;
    const urls = this.downloadUrls;
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "download.zip";
    let downloadFiles = "";
    if (urls.length > 1) {
      component.downloadActive = true;
      component.cancelDownloads = false;

      urls.forEach((url) => {
        const filename = url.split("/")[8];

        if (component.cancelDownloads) {
          return;
        }

        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (component.cancelDownloads) {
            return;
          }
          downloadFiles += `${filename}, `
          zip.file(filename, data, { binary: true });
          count++;

          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
              component.downloadActive = false;
              component.downloadFinishEvent(downloadFiles);
              if (component.user.cmsUser == null) {
                component.addDownload();
              }
            });
          }
        });
      });
    } else {
      for (const x of urls) {
        component.downloadActive = true;

        this.downloadSubscription = this.http
          .get(x, { responseType: "blob" })
          .subscribe(
            (blob: Blob) => {
              const filename = x.split("/")[8];

              saveAs(blob, filename);
              component.downloadActive = false;
              component.downloadFinishEvent(downloadFiles);
              if (component.user.cmsUser == null) {
                component.addDownload();
              }

            },
            (error) => {
              component.downloadActive = false;
            }
          );
      }
    }
    this.downloadBoxService.createDownloadBox().subscribe((response) => { }, (error) => console.log(error));
  }

  getDownloadBox() {
    this.loader = true;
    this.mcService.downloadBox(this.cart).subscribe(
      (response: any) => {

        this.loader = false;
        this.downloadMediaResponse = response;
        if (
          this.downloadMediaResponse.mustRegisterToDownload &&
          localStorage.getItem("Token") === null
        ) {
          this.showLoginMessage = true;
          return;
        }
        let mediaLength = 0;

        for (const item of this.downloadMediaResponse.downloadBoxList) {
          for (const media of item.media) {
            mediaLength++;
          }
        }

        // if (this.cart.length > mediaLength) {
        //   this.cart = [];

        //   for (const item of this.downloadMediaResponse.downloadBoxList) {
        //     for (const media of item.media) {
        //       this.cart.push(media.media.id);
        //     }
        //   }
        //   this.myFilesService.setMyFiles(this.cart);
        //   console.error(
        //     "Some of the selected media has been removed from the story."
        //   );
        // }
        for (const item of this.downloadMediaResponse.downloadBoxList) {
          for (const media of item.media) {
            this.downloadUrls.push(media.downloadLinks[0].downloadUrl);
          }
        }

        this.initVideoFormats(this);
      },
      (error2) => {
        this.loader = false;
        this.showLoginMessage = true;
      }
    );
  }

  initVideoFormats(component) {
    component.videoFormats = [];

    for (const download of component.downloadMediaResponse.downloadBoxList) {
      for (const media of download.media) {
        for (const link of media.downloadLinks) {
          if (
            media.media.mediaType === "Video" &&
            !component.videoFormats.find((x) => x.label === link.label)
          ) {
            component.videoFormats.push({ label: link.label });
          }
        }
      }
    }
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    const niz = new DownloadAddToModel();
    niz.publishedMediaIds = this.cart.publishedMediaIds;
    niz.storyMediaIds = this.cart.storyMediaIds;
    niz.userId = this.user.id;
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId.toString()
      );
    });
    niz.publishedMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download-pm",
        niz.userId.toString()
      );
    })
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => { }
    );
  }
}
