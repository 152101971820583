<div class="unsubscribe-container">
  <div class="section-headline">{{ language.newsletter }}</div>
  <div class="headline">{{ language.suscribeConfirmation }}</div>
  <div class="buttons">
    <div class="my-files-footer-button generic-button" [ngStyle]="{
        color: styles.style.textButton1Color,
        background: styles.style.mainIdentifyingColor
      }" (mouseover)="buttonsHelper.hoverGenericButton($event, styles)"
      (mouseout)="buttonsHelper.unhoverGenericButton($event, styles)" (click)="unsubscribe()">
      Yes
    </div>
    <div class="my-files-footer-button generic-button" [ngStyle]="{
        color: styles.style.textButton1Color,
        background: styles.style.mainIdentifyingColor
      }" (mouseover)="buttonsHelper.hoverGenericButton($event, styles)"
      (mouseout)="buttonsHelper.unhoverGenericButton($event, styles)" (click)="cancel()">
      No
    </div>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>