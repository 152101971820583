import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from '../../shared/services/http-request.service';

@Injectable()
export class ContactsService {

  constructor(private http: HttpRequestService) {
  }

  private mediaCenterWebContactsUrl = environment.apiUrl + '/MediaCenterWeb/MediaCenterWebAboutUs';

  mediaCenterWebContacts() {
    return this.http.get(this.mediaCenterWebContactsUrl);
  }

}
