import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { McService } from "../../shared/services/mc-service";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { PageStyle } from "../../models/style/style.model";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { WebNewsListModel, WebNewsModel } from "../news/news-model";

declare var $: any;

@Component({
  selector: "app-news",
  templateUrl: "./brand-stories.component.html",
  styleUrls: ["./brand-stories.component.less"],
})
export class BrandStoriesComponent implements OnInit {
  news: any[];
  grid: string;
  dropdown = false;
  loader: boolean;
  subcategory = false;
  styles = new PageStyle();
  user: any;
  showPagination = false;
  socials: string[] = [];
  currentUrl: string;
  newsHeadlineLength = 10;
  webListResponse = new WebNewsListModel();
  p = 1;
  itemsPerPage: number;
  shortDescriptionLength: number;
  language: Language;
  sLng: number;
  clientName: string;
  storyGroups: WebNewsModel[][] = [[]];

  constructor(
    private router: Router,
    private mcService: McService,
    private languageService: LanguageService,
    ngZone: NgZone,
    public fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientName = params["brand"];
    });

    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    window.onresize = (e) => {
      ngZone.run(() => {
        if (window.innerWidth <= 1100 && window.innerWidth >= 800) {
          this.grid = "Columns3";
        } else if (window.innerWidth <= 800) {
          this.grid = "Columns2";
        } else {
          this.grid = this.webListResponse.newsGridType;
        }
        // //
      });
    };
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resize();
  }
  ngOnInit() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.currentUrl = window.location.href;
    this.loader = true;
    window.scrollTo(0, 0);
    this.user = TokenManagerService.getUser();
    this.getBrandStories(this.p);
    // this.getStyle();
  }

  changeColorr(e) {
    e.target.style.background = this.styles.style.mainButton3HoverColor;
  }

  changeColor(e) {
    e.target.style.background = this.styles.style.mainButton3DefaultColor;
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.replace("News", "story") +
      "/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.webListResponse.news.find((x) => x.staggingStoryId == storyId)
          .storyTitle
      )
    );
  }

  getStyle() {
    this.mcService.getStyle().subscribe(
      (response: any) => {
        //
        this.styles = response;
      },
      (error) => {
        //
      }
    );
  }

  getBrandStories(page) {
    this.loader = true;
    if (this.sLng == 0) {
      this.sLng = 1;
    }
    this.mcService.getBrandStories(this.clientName, page, this.sLng).subscribe(
      (response: any) => {
        this.webListResponse = response;
        if (this.webListResponse.socialSharing != null) {
          this.socials = this.webListResponse.socialSharing;
        }
        this.groupStories();
        this.resize();

        this.itemsPerPage = this.webListResponse.maxNewsPerPage;
        this.loader = false;

        setTimeout(() => {
          const element = document.getElementsByClassName(
            "current"
          ) as HTMLCollectionOf<HTMLElement>;
          if (element[0]) {
            element[0].style.backgroundColor =
              "" + this.styles.style.mainIdentifyingColor;
          }
        });
      },
      (error) => {
        //
      }
    );
  }

  groupStories() {
    let count = 0;
    this.storyGroups = [[]];
    for (const story of this.webListResponse.news) {
      if (this.storyGroups[count].length >= 4) count++;
      if (!this.storyGroups[count]) this.storyGroups[count] = [];
      this.storyGroups[count].push(story);
    }
  }

  resize() {
    if (this.webListResponse.newsGridType === "Columns2") {
      this.newsHeadlineLength = 155;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 120;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 110;
      }
    }
    if (this.webListResponse.newsGridType === "Columns3") {
      this.newsHeadlineLength = 90;
      this.shortDescriptionLength = 140;

      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 65;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 120;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 75;
        this.shortDescriptionLength = 75;
      }
    }
    if (this.webListResponse.newsGridType === "Columns4") {
      this.newsHeadlineLength = 60;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 15;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 110;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 100;
      }
    }
  }

  setColor(e?) {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "current"
      ) as HTMLCollectionOf<HTMLElement>;
      if (element[0]) {
        element[0].style.backgroundColor =
          "" + this.styles.style.mainIdentifyingColor;
      }
    }, 100);
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  openDropdown(id) {
    for (const x of this.webListResponse.news) {
      if (x.storyId === id) {
        x.shareDropdown = !x.shareDropdown;
      }
    }
  }

  readMore(id) {
    localStorage.setItem("storyId", JSON.stringify(id));
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
      // this.mcService.addViewToNews(id.storyId, this.user.id)
      //   .subscribe((response) => {
      //
      //     },
      //     (error2 => {
      //       //
      //     }));
    }
    this.router.navigate([
      "/story/" +
        id.staggingStoryId +
        "/" +
        UrlHelper.getUrlTitle(
          this.webListResponse.news.find(
            (x) => x.staggingStoryId == id.staggingStoryId
          ).storyTitle
        ),
    ]);
  }

  twoGrid() {
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 3) ||
      (this.grid === "Columns4" && this.itemsPerPage === 4)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 2;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 6) ||
      (this.grid === "Columns4" && this.itemsPerPage === 8)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 4;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 9) ||
      (this.grid === "Columns4" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 6;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 12) ||
      (this.grid === "Columns4" && this.itemsPerPage === 3)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 8;
    }
    this.setColor();
    this.newsHeadlineLength = 155;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 120;
    }
  }

  threeGrid() {
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 2) ||
      (this.grid === "Columns4" && this.itemsPerPage === 4)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 3;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 4) ||
      (this.grid === "Columns4" && this.itemsPerPage === 8)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 6;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 6) ||
      (this.grid === "Columns4" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 9;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 8) ||
      (this.grid === "Columns4" && this.itemsPerPage === 16)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 12;
    }
    this.setColor();
    this.newsHeadlineLength = 90;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 65;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 12;
    }
  }

  fourGrid() {
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 2) ||
      (this.grid === "Columns3" && this.itemsPerPage === 3)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 4;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 4) ||
      (this.grid === "Columns3" && this.itemsPerPage === 6)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 8;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 6) ||
      (this.grid === "Columns3" && this.itemsPerPage === 9)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 12;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 8) ||
      (this.grid === "Columns3" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 16;
    }
    this.setColor();
    this.newsHeadlineLength = 60;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 42;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 42;
    }
  }

  changePage(page) {
    this.p = page;
    this.getBrandStories(this.p);
  }
}
