import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes/routes.module';
import { RouterModule } from '@angular/router';
import { FacebookModule } from 'ngx-facebook';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordAdminComponent } from './login/reset-password-admin/reset-password-admin.component';
import { RegisterImportedComponent } from './register-imported/register-imported.component';
import { ErrorService } from "./shared/services/error-service";
import { HttpClientModule } from '@angular/common/http';
import { OutBoundLinkClickInterceptService } from "./shared/services/outbound-link-click-intercept.service";
@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordAdminComponent,
    RegisterImportedComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    RoutesModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'inline',
    }),
    ToastContainerModule,
    BrowserAnimationsModule,
    FacebookModule.forRoot(),
    HttpClientModule,
  ],
  providers: [ErrorService, OutBoundLinkClickInterceptService],
  bootstrap: [AppComponent]
})
export class AppModule { }
