import { Injectable } from '@angular/core';
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";

@Injectable() export class OutBoundLinkClickInterceptService {

  constructor(private googleAnalytics: GoogleAnalyticsEventsService) {
    document.onclick = this.interceptHref;
  }

  interceptHref = (_event) => {

    const tEvent = _event || window.event;
    let element = tEvent.target || tEvent.srcElement;

    while (element.parentElement != null && element?.tagName != 'A' && element?.tagName != 'P') {
      element = element.parentElement;
    }

    const linkedClicked = element?.tagName == 'A'
    if (linkedClicked && element.href.trim() != '') {
      const externalUrl = new URL(element.href).origin !== location.origin;
      if (externalUrl) {
        this.googleAnalytics.emitEvent('ClickExternalLink', 'ClickLinks', 'ExternalLink', element.textContent + " " + element.href);
      }
    }

  }
} 