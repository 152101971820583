export class LoginModel {
  public email: string;
  public password: string;
}

export class UserModel {
  public id: number;
  public fullName: string;
  public role: string;
  public isPasswordSet: boolean;
  public cmsUser: CmsModel;
}

export class CmsModel{
  public id: number;
  public fullName: string;
  public role: string;
  public imageUrl: string;
}

export class EmailModel{
  public email: string;
}


export class NewPasswordModel {
  public resetCode: string;
  public newPassword: string;
  public confirmPassword: string;
}

export class UserAuthModel{
  public code: string;
}

export class fbModel {
  public firstName: string;
  public lastName: string;
  public email: string;
  public facebookId:string;
  constructor(){
    this.email='';
    this.facebookId='';
    this.firstName='';
    this.lastName='';
  }
}
