import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { LoginModel, NewPasswordModel } from "../login-model";
import { Language } from "../../models/language/language.model";
import { LanguageService } from "../../shared/services/language.service";
import { StorageService } from "../../shared/services/storage.service";
import { ToastrConfig } from "../../shared/helpers/toastr-config";
import { PasswordCheckService } from "../../shared/services/password-check.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.less"],
})
export class ResetPasswordComponent implements OnInit {
  valForm: FormGroup;
  loader = false;
  data = new NewPasswordModel();
  patternPassword = /^\S*$/;
  confirmError: boolean;
  language: Language;

  constructor(
    private fb: FormBuilder,
    private languageService: LanguageService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private authLoginService: TokenManagerService,
    private router: Router,
    private sService: StorageService,
    public checkPasswordService: PasswordCheckService
  ) {
    this.valForm = this.fb.group({
      "new-password": [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.patternPassword),
        ]),
      ],
      confirmpassword: [null, Validators.required],
    });
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let userId = params["id"];
      this.data.resetCode = userId;

      this.authLoginService.checkPasswordCode(this.data.resetCode).subscribe(
        (response: any) => {

          const body: string = response;

          if (body === "null") {

            this.toastr.error('Reset link has already been used.', 'Error', ToastrConfig.error);
            this.router.navigate(["./sign-in"]);
          }
        },
        (error) => { }
      );
    });


    // this.authLoginService.checkAuthentication(this.data.resetCode)
    //   .subscribe(
    //     (response) => {
    //
    //     }, (error) => {
    //       const toast: Toast = {
    //         type: 'error',
    //         title: 'Error',
    //         body: 'Reset link has already been used.',
    //         showCloseButton: true,
    //         timeout: 5000,
    //         closeHtml: '<div class="toaster-close-icon"></div>'
    //       };
    //
    //       this.toasterService.pop(toast);
    //       this.router.navigate(['./login']);
    //     }
    //   );
  }

  checkPassword() {
    if (
      this.valForm.controls["new-password"].value ===
      this.valForm.controls["confirmpassword"].value
    ) {
      this.confirmError = false;
    }
  }

  isPwFocus: boolean = false;
  onPwFocus() {
    this.isPwFocus = true;
  }
  onPwBlur() {
    this.isPwFocus = false;
  }

  reset(e, data) {
    e.stopPropagation();

    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.checkPasswordService.hasPasswordHardening() && !this.checkPasswordService.checkAll(data.newPassword)) {
      this.toastr.error('Password does not meet the requirements', 'Error', ToastrConfig.error);
      return;
    }

    if (this.valForm.valid) {
      this.loader = true;
      this.authLoginService.ResetPassword(data).subscribe(
        (response: any) => {
          this.toastr.success('Password changed successfully!', 'Well done!', ToastrConfig.success);
          const loginModel = new LoginModel();
          loginModel.email = response.email;
          loginModel.password = data.newPassword;

          this.authLoginService.login(loginModel).subscribe(
            (res) => {
              this.loader = false;
              const loggedUserName = TokenManagerService.getUser().fullName;
              // this.isPassowordSet = TokenManagerService.getUser().isPasswordSet;
              this.sService.setName(loggedUserName);
              // this.sService.setPw(this.isPassowordSet);
              this.router.navigate(["/profile"]);
            },
            (error) => {
              //
            }
          );

          // this.router.navigate(['/login']);
        },
        (error) => {
          this.toastr.error(error.error.message, 'Error', ToastrConfig.error);
        }
      );
    }
  }
}
