<section class="media-group" [ngClass]="{ 'mirrored': !mirrored }">
  <div class="big-container">
    <app-media-file *ngIf="group.length > 0" [media]="group[0]" [config]="config" [full]="true"
      [customClasses]="'two-thirds'"></app-media-file>
    <app-media-file *ngIf="group.length > 3" [media]="group[3]" [config]="config" [full]="true"
      [customClasses]="'one-third'"></app-media-file>
  </div>
  <div class="small-container">
    <app-media-file *ngIf="group.length > 1" [media]="group[1]" [config]="config" [full]="true"
      [customClasses]="'one-half'"></app-media-file>
    <app-media-file *ngIf="group.length > 2" [media]="group[2]" [config]="config" [full]="true"
      [customClasses]="'one-half'"></app-media-file>
  </div>
</section>