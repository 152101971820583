import { Component, OnInit } from '@angular/core';
import { McService } from '../../shared/services/mc-service';
import { BrandsManageModel, BrandsModel } from './brands-model';
import { Router } from '@angular/router';
import { TokenManagerService } from '../../shared/services/token-manager.service';
import { StorageService } from '../../shared/services/storage.service';
declare var $: any;
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../models/language/language.model';
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import local_store from 'devextreme/data/local_store';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.less']
})
export class BrandsComponent implements OnInit {
  dropdown = false;
  language: Language;
  categoryLabel: string;
  category = ['company', 'company', 'company'];
  visibleBrands: BrandsManageModel[] = [];
  noBrands = false;
  searchResult: boolean;
  styles = new PageStyle();

  brandsResponse = new BrandsModel();
  loader: boolean;
  query: any;

  constructor(private mcService: McService, private languageService: LanguageService, public fontsAndColorsService: FontsAndColorsService, private router: Router, private sService: StorageService) {
    this.language = languageService.getLanguage();
    this.styles = fontsAndColorsService.getPageStyleInfo();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

  }

  ngOnInit() {
    this.getBrands();
    this.sService.getSearch().subscribe(term => {
      this.searchResult = term;
    });
    this.categoryLabel = this.language.categories;
  }

  getBrands() {
    this.loader = true;
    this.mcService.getBrands()
      .subscribe((response: any) => {
        this.brandsResponse = response;
        // if (this.brandsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
        //   this.router.navigateByUrl('/sign-in');
        // }
        this.visibleBrands = response.clients;
        this.loader = false;
        if (this.brandsResponse.customCategories && this.brandsResponse.customCategories.length > 0)
          this.brandsResponse.customCategories.unshift({ id: 0, parentId: 0, label: 'All' });
        if (this.brandsResponse.mainCategories && this.brandsResponse.mainCategories.length > 0)
          this.brandsResponse.mainCategories.unshift({ id: 0, parentId: 0, label: 'All' });
      },
        (error) => {
          //
        });
  }

  // mediaCenter(media) {
  //   if (media != null || media.length != 0) {
  //     window.location.href = media;
  //   }
  // }

  brandStories(brandName) {
    this.router.navigateByUrl('/brands/' + brandName);
  }

  filter(ccId) {
    this.visibleBrands = [];
    for (const client of this.brandsResponse.clients) {
      let niz2 = client.customCategories ? client.customCategories.filter(x => x.id === ccId.id) : client.mainCategories.filter(x => x.id === ccId.id);
      if (niz2.length > 0) {
        this.visibleBrands.push(client);
      }
      if (ccId.id === 0) {
        this.visibleBrands.push(client);
      }
    }
    // if (this.visibleBrands.length === 0) {
    //   this.noBrands = true;
    // }
  }

  goToMc(url, clientId) {
    this.mcService.redirectToMc(clientId).subscribe(
      (response: any) => {
        const token = response.token;
        if (url) 
          window.location.href = url + '&token=' + token;
      },
      (error) => {
        if (url)
          window.location.href = url;
      });
  }

}
