import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WebpHelperService {
  constructor() {}

  changeFileToWebP(url: string): string {
    if (url == null || url == undefined) return "";
    return url.replace(/\.[^/.]+$/, ".webp");
  }

  removeWebP(event): void {
    const target = event.target;
    const parent = target.parentElement;
    const imgElement = parent.querySelector("img");

    if (parent.children[0].type == "image/webp") {
      parent.children[0].remove();
    } else if (parent.children[0].type == "image/jpeg") {
      parent.children[0].remove();
      imgElement.classList.add("document-overlay");
    }
  }
}
