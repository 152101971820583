<div class="popup-background" (click)="$event.stopPropagation()">
  <div class="popup-overlay">
    <div class="popup" [ngClass]="popupType === 'big' ? 'big' : ''">
      <div class="close-icon close-popup-icon" (click)="closeEmbeddedPopup(); $event.stopPropagation()"></div>
      <div class="popup-content-wrapper">
        <div class="popup-content">
          <label for="">Embedded code</label>
          <textarea id="" cols="30" rows="5" style="resize: none" [ngModel]="embeddedLink"></textarea>
          <div class="button-wrapper flex-center">
            <button class="download-button flex-center" (cbOnSuccess)="isUrlCopied = true" ngxClipboard
              [cbContent]="embeddedLink" [disabled]="isUrlCopied" [ngStyle]="{
                background: styles.style
                  ? styles.style.mainIdentifyingColor
                  : ''
              }" (click)="$event.stopPropagation()">
              Copy to clipboard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>