import { Component, Input, OnInit } from "@angular/core";
import { LatestStoriesModel } from "../../homepage/home-model";

declare var $: any;

@Component({
  selector: "app-news-group",
  templateUrl: "./news-group.component.html",
  styleUrls: ["./news-group.component.less"],
})
export class NewsGroupComponent implements OnInit {
  @Input() group: LatestStoriesModel[];
  @Input() config: any;
  @Input() mirrored: boolean;

  constructor() {
  }

  ngOnInit() {}
}
