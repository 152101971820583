import { Injectable } from '@angular/core';
import { HttpRequestService } from "./http-request.service";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountService {

  constructor(private http: HttpRequestService) { }

  deleteAccount(password: string) {
    const data = {
      password: password
    }
    return this.http.post(environment.apiUrl + '/mediacenteruser/deleteuser', data);
  }
}
