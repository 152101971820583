<section class="download flex-center">
  <div class="gray-background"></div>
  <div class="content-wrapper">
    <div class="download-feed">
      <div class="download-bar flex-center">
        <h1 class="section-headline" [ngStyle]="{
            color: styles.style ? styles.style.textH2Color : ''
          }">
          {{ language.downloadBox }}
        </h1>
        <div class="all-formats flex-center">
          <span class="download-message">{{ language.downloadVideoInFormat }}:</span>
          <div class="form-item flex">
            <app-dropdown [dropdownLabel]="categoryLabel" [sourceList]="videoFormats" [dropdownSize]="'bigger'"
              (filtered)="downloadVideoType($event)"></app-dropdown>
          </div>
          <div class="button flex-center" [ngStyle]="{
              background: styles.style?.mainIdentifyingColor
            }" (click)="shareCart()">
            {{ language.shareCart }}
          </div>
          <div *ngIf="shareCartUrl" class="share-box-wrapper flex">
            <div class="url">{{ shareCartUrl }}</div>
            <div class="share-buttons">
              <button class="button" [ngStyle]="{
                  background: styles.style?.mainIdentifyingColor
                }" (cbOnSuccess)="isUrlCopied = true" ngxClipboard [cbContent]="shareCartUrl" [disabled]="isUrlCopied">
                Copy
              </button>
              <div class="close-icon" (click)="shareCartUrl = ''; isUrlCopied = false"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="download-from-story" *ngFor="let dl of downloadMediaResponse?.downloadBoxList; let i = index">
        <div class="download-source" [ngStyle]="{
            color: styles.style ? styles.style.textH2Color : ''
          }">
          {{ dl.storyTitle }}
        </div>
        <div class="download-wrapper flex">
          <div class="download-box" *ngFor="let dl of dl.media">
            <div class="download-image">
              <div class="play-button-holder" (click)="openMediaPopupAutoplay(dl.media)">
                <div *ngIf="dl.media.mediaType === 'Video'" class="play-button flex-center">
                  <i [ngStyle]="{
                      color: styles.style
                        ? styles.style.mainIdentifyingColor
                        : ''
                    }" class="fas fa-play"></i>
                </div>
              </div>
              <picture *ngIf="dl.media.mediaType === 'Image'" (click)="openMediaPopup(dl.media.id)">
                <source srcset="{{ webPHelper.changeFileToWebP(dl.media.thumbUrl) }}" type="image/webp" />
                <source srcset="{{ dl.media.thumbUrl }}" type="image/jpeg" />
                <img (error)="webPHelper.removeWebP($event)" src="{{ dl.media.thumbUrl }}" alt="Download image" />
              </picture>
              <img src="{{ dl.media.thumbUrl }}" *ngIf="dl.media.mediaType === 'Document'"
                onerror="this.src = 'assets/images/document_default.png'" alt="dl.media.name"
                (click)="openMediaPopup(dl.media.id)" />
              <img src="{{ dl.media.thumbUrl }}" *ngIf="dl.media.mediaType === 'Audio'"
                onerror="this.src = 'assets/images/audio_default.png'" alt="dl.media.name"
                (click)="openMediaPopup(dl.media)" />
              <img src="{{ dl.media.thumbUrl }}" alt="dl.media.name" *ngIf="dl.media.mediaType === 'Video'" />
            </div>
            <div class="download-info">
              <div class="download-name flex-center" (click)="openMediaPopup(dl.media)">
                {{ dl.media.name | slice : 0 : 10
                }}<span *ngIf="dl.media.name >= 10">...</span>
              </div>
              <div class="file-information flex-center" *ngFor="let label of dl.downloadLinks">
                <div *ngIf="
                    label.label.includes('ORIGINAL') ||
                    label.label.includes('ORGINAL') ||
                    label.label.includes('XLSX') ||
                    label.label.includes('TXT') ||
                    label.label.includes('PPTX') ||
                    label.label.includes('DOCX') ||
                    label.label.includes('PDF')
                  " class="file-type">
                  <img *ngIf="dl.media.mediaType === 'Image'" class="instagram-icon"
                    src="../../assets/images/instagram.svg" alt="Image" />
                  <img *ngIf="dl.media.mediaType === 'Video'" class="play-icon" src="../../assets/images/play.svg"
                    alt="Video" />
                  <img *ngIf="dl.media.mediaType === 'Document'" class="file-icon" src="../../assets/images/file.svg"
                    alt="Document" />
                  <img *ngIf="dl.media.mediaType === 'Audio'" class="sound-icon" src="../../assets/images/sound.svg"
                    alt="Audio" />
                </div>
                <div class="file-size flex-center">
                  <span *ngIf="
                      label.label.includes('ORIGINAL') ||
                      label.label.includes('ORGINAL') ||
                      label.label.includes('XLSX') ||
                      label.label.includes('TXT') ||
                      label.label.includes('PPTX') ||
                      label.label.includes('DOCX') ||
                      label.label.includes('PDF')
                    ">{{ label.label }}</span>
                  <input *ngIf="
                      label.label.includes('ORIGINAL') ||
                      label.label.includes('ORGINAL') ||
                      label.label.includes('XLSX') ||
                      label.label.includes('TXT') ||
                      label.label.includes('PPTX') ||
                      label.label.includes('DOCX') ||
                      label.label.includes('PDF')
                    " (click)="mediaChecked(label, dl.media, $event)" type="checkbox" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button *ngIf="!downloadActive && !loader" class="download-button flex-center" [disabled]="disabledButton"
      [ngClass]="[disabledButton ? 'disabledButton' : '']" (click)="download()" [ngStyle]="{
        background: styles.style?.mainIdentifyingColo
      }">
      {{ language.download }}
    </button>
    <div class="cancel-wrapper" *ngIf="downloadActive">
      <div class="cancel-download-loader-wrap">
        <app-loader [blockScroll]="false"></app-loader>
      </div>
      <button class="download-button flex-center" (click)="cancelDownload()">
        {{ language.cancel }}
      </button>
    </div>
  </div>
</section>
<app-popup *ngIf="popup" [(popup)]="popup" [(hideCounters)]="downloadMediaResponse.hideCounters" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup" (loginPopupChange)="closeLoader()"></app-loginpopup>