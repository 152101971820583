<section class="social flex-center">
  <div class="social-icons flex-center">
    <img src="../../assets/images/facebook.svg" alt="Facebook">
    <img src="../../assets/images/twitter.svg" alt="Twitter">
    <img src="../../assets/images/instagram.svg" alt="instagram">
  </div>
  <div>@DNA</div>
  <div class="social-wrapper flex">
    <div class="social-row-one flex">
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
    </div>
    <div class="social-row-two flex">
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
      <div class="social-box">
        <div class="person-detail">
          <img src="" alt="Person detail">
        </div>
      </div>
    </div>
  </div>
</section>
