import { Injectable } from '@angular/core';
import { LoginAttempts } from '../../models/login-attempts/login-attempts-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginAttemptsService {

  constructor(private http: HttpClient) { }


  getLoginAttempts(userId: number, data): Observable<LoginAttempts[]> {
    const url = environment.apiUrl + '/CmsLoginAttempts/List?cmsUserId=' + userId + '&page=' + data.page + '&pageSize=' + data.pageSize;
    return this.http.get<LoginAttempts[]>(url);
  }

  getLoginAttempt(id: number): Observable<LoginAttempts> {
    const url = environment.apiUrl + '/UserLoginAttempts/get?id=' + id;
    return this.http.get<LoginAttempts>(url);
  }

  createLoginAttempts(loginAttempts: LoginAttempts): Observable<LoginAttempts> {
    const url = environment.apiUrl + '/UserLoginAttempts/add';
    return this.http.post<LoginAttempts>(url, loginAttempts);
  }

  deleteLoginAttempts(id: number): Observable<any> {
    const url = environment.apiUrl + '/UserLoginAttempts/delete?id=' + id;
    return this.http.delete(url);
  }

  updateLoginAttempts(loginAttempts: LoginAttempts): Observable<LoginAttempts> {
    const url = environment.apiUrl + '/UserLoginAttempts/update';
    return this.http.post<LoginAttempts>(url, loginAttempts);
  }


}
