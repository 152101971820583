import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PageStyle } from '../../../models/style/style.model';
import { FontsAndColorsService } from '../../services/fonts-and-colors.service';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-embedded-share',
  templateUrl: './embedded-share.component.html',
  styleUrls: ['./embedded-share.component.less']
})
export class EmbeddedShareComponent implements OnInit {
  @Input() embeddedPopup: boolean;
  @Input() title: string;
  @Input() mediaId: string;
  @Input() isPublished: boolean = false;
  @Input() popupType: string = '';
  @Output() embeddClose = new EventEmitter();

  styles = new PageStyle();
  embeddedLink: string;
  isUrlCopied: boolean;

  constructor(private fontsAndColorsService: FontsAndColorsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {

      // this.closeEmbeddedPopup();
    }
  }

  closeEmbeddedPopup() {
    this.embeddClose.emit(false);
  }

  ngOnInit() {
    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    this.embeddedLink = '<iframe title="' + this.title + '" width="640" height="360" src="' + environment.apiUrlRoot + '/embed/media?id=' + headerFooter.mediaCenterId + '&mediaId=' + this.mediaId + '&isPublished=' + this.isPublished + '" frameborder="0" allowfullscreen><p>iframes are not supported by your browser.</p></iframe><br />';
  }
}
