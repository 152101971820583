<div class="story-language">
    <div class="head">
        <div class="language" *ngIf="marketName">{{ marketName }}</div>
        <div class="add-to-fav" (click)="toggleSavedStory(story.storyId)">
            <i *ngIf="!inSavedStories(story.storyId)" class="far fa-heart"></i>
            <i *ngIf="inSavedStories(story.storyId)" class="fas fa-heart"></i>
        </div>
    </div>
    <h2>({{ story.code }}) {{ story.storyTitle }}</h2>
    <div class="buttons">
        <button class="view-article" (click)="readMore(story)">{{ language.viewArticle }}</button>
        <button class="download-all" (click)="download(story.downloadUrls, story.mediaIds)">
            {{ language.downloadAll }}
            <span>
                (+{{story.downloadUrls.length}} {{ language.items }})
            </span>
        </button>
    </div>
</div>