<section class="testimonials flex-center">
  <div class="content-wrapper">
    <div class="testimonials-feed">
      <h1 class="section-headline">{{language.testimonials}}</h1>
      <div class="testimonials-wrapper flex asd">
        <div class="testimonial-box" *ngFor="let testimonial of testimonialsResponse.testimonials">
          <div class="person-detail flex-center">
            <div class="person flex-center">
              <img src="{{testimonial.imageUrl}}" alt="Profile photo" class="profile-photo">
              <h4 class="person-name">
                {{testimonial.name}}</h4>
            </div>
            <img src="{{testimonial.clientLogoUrl}}" alt="Company Logo" class="company-logo">
          </div>
          <div class="person-testimonial flex">
            <p>{{(testimonial.text?.length>220)? (testimonial.text | slice:0:220)+'...':(testimonial.text)}}</p>
            <div class="date">{{testimonial.date | date:
              'dd MMM yyyy'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>