<div class="story-group">
    <div class="story-info">
        <div class="story-date">{{ storiesByCode.date | date: 'mediumDate' }}</div>
        <h2>({{ storiesByCode.code }}) {{ storiesByCode.name }}</h2>
    </div>
    <div class="story-languages" #carousel>
        <ng-container *ngFor="let story of storiesByCode.stories">
            <app-article *ngFor="let market of story.markets" [story]="story" [marketName]="market.name"
                [mustRegisterToDownload]="mustRegisterToDownload" [anonymousShare]="anonymousShare"></app-article>
            <app-article *ngIf="story.markets == null || story.markets.length == 0" [story]="story"
                [mustRegisterToDownload]="mustRegisterToDownload" [anonymousShare]="anonymousShare"></app-article>
        </ng-container>
    </div>
</div>