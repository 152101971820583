import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { WebpHelperService } from '../../services/webp-helper.service';
@Component({
  selector: 'app-slider-picture',
  templateUrl: './slider-picture.component.html',
  styleUrls: ['./slider-picture.component.less']
})
export class SliderPictureComponent implements OnInit {

  @Input() backgroundUrl: string;
  webPError: boolean = false;

  constructor(public webPHelper: WebpHelperService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.webPError = false;
  }

  onError() {
    this.webPError = true;
    console.log("Error en " + this.backgroundUrl);
  }
}
