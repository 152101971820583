export class UrlHelper {

  static getUrlTitle(title: string) {
    title = title.replace(/[\W_]+/g, '-').toLowerCase();
    title = title.replace(/\//g, '-').toLowerCase();

    if (title.indexOf('-') == 0) {
      title = title.substring(1, title.length - 1);
    }

    if (title.charAt(title.length - 1) == '-') {
      title = title.slice(0, -1);
    }
    title = title.replace(/assets/g,'');
    return title;
  }

  static getCurrentUrlHostOnly() {
    const split = window.location.href.split('/');
    return split[0] + '//' + split[2];

  }

  static replaceFullUrlTitle(url) {
    const split = url.split('/');

    split[2] = this.getUrlTitle(split[2]);

    let newUrl = '';

    split.forEach(s => newUrl += ('/' + s));

    return newUrl;
  }
}
