import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Language } from "../../../models/language/language.model";
import { LanguageService } from "../../../shared/services/language.service";

declare var $: any;


@Component({
  selector: 'app-code-story',
  templateUrl: './code-story.component.html',
  styleUrls: ['./code-story.component.less']
})
export class CodeStoryComponent implements AfterViewInit {

  @Input() storiesByCode: any;
  @Input() mustRegisterToDownload: boolean;
  @Input() anonymousShare: boolean;
  @ViewChild("carousel") carousel: any;
  language: Language;


  slideConfig = {
    slidesToShow: 3.5,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
        },
      },
    ]
  };

  constructor(private languageService: LanguageService) {
    this.language = languageService.getLanguage();
  }

  ngAfterViewInit() {
    $(this.carousel.nativeElement).slick(this.slideConfig);
  }
}
