import {Injectable} from '@angular/core';
import {Language} from '../../models/language/language.model';
import {Subject} from "rxjs";

@Injectable()
export class LanguageService {

  public languageId: Subject<number> = new Subject();
  public language: Subject<Language> = new Subject();

  pageLanguage: Language;

  constructor() {
  }

  getLanguage() {
    if (!this.pageLanguage)
      this.pageLanguage = new Language();

    return this.pageLanguage;
  }


  setLanguageId(languageId: number) {
    localStorage.setItem('lng', languageId.toString());
    this.languageId.next(languageId);
  }

  setLanguage(pageLanguage: Language) {
    this.pageLanguage = pageLanguage;
    this.language.next(pageLanguage);
  }

}
