export class ContactModel {
  public emailsContactForm: string;
  public longitude: number;
  public latitude: number;
  public positionName: string;
  public longitude2: number;
  public latitude2: number;
  public positionName2: string;
  public newBusinessEnquires: boolean;
  public title: string;
  public subtitle: string;
  public person1Name: string;
  public telephone: string;
  public telephone2: string;
  public email1: string;
  public email2: string;
  public person1Position: string;
  public person1Email: string;
  public person2Name: string;
  public person2Position;
  public person2Email: string;
  public emailModel: EmailModel;
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public newBusinessEnquiresTitle: string;

  constructor(){
    this.emailModel = new EmailModel();
  }

}

export class EmailModel{
  public firstName: string;
  public lastName: string;
  public email: string;
  public telephone: string;
  public company: string;
  public message: string;
}
