<section class="register flex-center">
  <div class="gray-background"></div>
  <div class="register-wrapper">
    <div class="headline">User profile</div>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="onSubmit($event)">
      <div class="form-element flex">
        <label for="name">{{ language.firstName }}</label>
        <input type="text" id="name" placeholder="Enter your first name"
          [(ngModel)]="registerResponseModel?.mediaUserModel.firstName" formControlName="firstname" />
        <span class="text-danger" *ngIf="
            valForm.controls['firstname'].hasError('required') &&
            (valForm.controls['firstname'].dirty ||
              valForm.controls['firstname'].touched)
          ">
          {{ language.firstNameRequired }}</span>
      </div>
      <div class="form-element flex">
        <label for="lastname">{{ language.lastName }}</label>
        <input type="text" id="lastname" placeholder="Enter your last name"
          [(ngModel)]="registerResponseModel?.mediaUserModel.lastName" formControlName="lastname" />
        <span class="text-danger" *ngIf="
            valForm.controls['lastname'].hasError('required') &&
            (valForm.controls['lastname'].dirty ||
              valForm.controls['lastname'].touched)
          ">
          {{ language.lastNameRequired }}</span>
      </div>
      <div class="form-element flex">
        <label for="email">{{ language.email }}</label>
        <input type="text" id="email" placeholder="{{language.enterEmail}}"
          [(ngModel)]="registerResponseModel?.mediaUserModel.email" (keyup)="checkIfEmpty()" formControlName="email" />
        <span class="text-danger" *ngIf="
            valForm.controls['email'].hasError('required') &&
            (valForm.controls['email'].dirty ||
              valForm.controls['email'].touched)
          ">
          {{ language.emailNameRequired }}</span>
        <span class="text-danger" *ngIf="
            valForm.controls['email'].hasError('email') &&
            (valForm.controls['email'].dirty ||
              valForm.controls['email'].touched)
          ">{{ language.invalidEmail }}</span>
      </div>
      <div class="form-element flex">
        <label for="phone">{{ language.telephone }}</label>
        <input type="text" id="phone" placeholder="Enter your phone number"
          [(ngModel)]="registerResponseModel?.mediaUserModel.telephoneNumber" formControlName="telephone" />
      </div>
      <div class="form-element flex" *ngIf="showPw">
        <label for="name">{{ language.password }}</label>
        <input autocomplete="off" type="password" id="password" (keyup)="setConfirmError()"
          placeholder="{{language.enterPassword}}" [(ngModel)]="registerResponseModel?.mediaUserModel.password"
          formControlName="password" />
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('maxlength') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched)
          ">
          {{ language.max50Characters }}</span>
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('minlength') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched)
          ">
          {{ language.min4Characters }}</span>
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('pattern') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched) &&
            !valForm.controls['newPassword'].hasError('minlength')
          ">
          {{ language.passwordNoSpaces }}</span>
      </div>
      <div class="form-element flex" *ngIf="showPw">
        <label for="lastname">{{ language.confirmPassword }}</label>
        <input [(ngModel)]="registerResponseModel?.mediaUserModel.confirmPassword" type="password" id="repeat-password"
          placeholder="Repeat your password" formControlName="confirmPassword" (keyup)="checkPassword()" />
        <span class="text-danger" *ngIf="
            valForm.controls['confirmPassword'].hasError('required') &&
            (valForm.controls['confirmPassword'].dirty ||
              valForm.controls['confirmPassword'].touched)
          ">
          {{ language.confirmPasswordRequired }}</span>
        <span class="text-danger" *ngIf="
            valForm.controls['confirmPassword'].value !=
              valForm.controls['password'].value &&
            !valForm.controls['confirmPassword'].hasError('required') &&
            valForm.controls['password'].value != ''
          ">{{ language.passwordsNoMatch }}</span>
      </div>
      <div class="form-element flex" *ngIf="isPasswordSet">
        <label for="lastname">{{ language.currentPassword }}</label>
        <input [(ngModel)]="registerResponseModel?.mediaUserModel.oldPassword"
          [ngClass]="[oldPassword ? 'ng-invalid ng-touched' : '']" (keyup)="ifEmpty()" type="password" id="old-password"
          placeholder="Enter your old password" formControlName="oldPassword" />
        <span class="text-danger" *ngIf="oldPassword">{{ language.mustEnterFirstPassword }}</span>
        <span class="text-danger" *ngIf="enterPw">{{ language.mustEnterOldPassword }}</span>
      </div>
      <div class="form-element flex">
        <label for="lastname">{{ language.companyName }}</label>
        <input type="text" id="company" placeholder="Enter your company name"
          [(ngModel)]="registerResponseModel?.mediaUserModel.companyName" formControlName="company" />
      </div>
      <div class="form-element flex">
        <label>{{ language.languages }}</label>
        <div class="select bigger register-dropdown flex-center" (click)="languageDropDown = !languageDropDown">
          <span *ngIf="languages.length === 0">{{ language.languages }}</span>
          <div class="flex select-list">
            <ng-container *ngFor="let lan of languages; let isLast = last">
              <div>{{ lan }}{{ isLast ? "" : ", " }}</div>
            </ng-container>
          </div>
          <div class="language-dropdown flex flex-column" *ngIf="languageDropDown">
            <div class="choice flex" *ngFor="let lang of registerResponseModel.languages"
              (click)="$event.stopPropagation(); addLanguage(lang)">
              <input type="checkbox" [checked]="lang.checked" />
              <div>{{ lang.label }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-element flex">
        <label>{{ language.userType }}</label>
        <div class="select bigger register-dropdown flex-center" (click)="mediaUser = !mediaUser">
          <span *ngIf="usersToShow.length === 0">Users</span>
          <div class="flex select-list">
            <ng-container *ngFor="let user of usersToShow; let isLast = last">
              <div>{{ user }}{{ isLast ? "" : ", " }}</div>
            </ng-container>
          </div>
          <div class="language-dropdown flex flex-column" *ngIf="mediaUser">
            <div class="choice flex" *ngFor="let user of registerResponseModel.userGroups"
              (click)="$event.stopPropagation(); addUser(user, $event)">
              <input type="checkbox" [checked]="user.checked" />
              <div>{{ user.name }}</div>
            </div>
          </div>
        </div>
        <div class="text-danger" *ngIf="userFilled == false">
          {{ language.mediaUserTypeRequired }}
        </div>
      </div>

      <div class="form-element social-handles flex">
        <label for="#">{{ language.socialHandles }}</label>
        <div class="add-wrapper">
          <div class="add-button flex" (click)="addDropdown = !addDropdown">
            <div class="add flex-center">
              <i class="far fa-plus"></i>
            </div>
            <div class="text-button flex-center">{{ language.add }}</div>
          </div>
          <div class="add-dropdown flex flex-column" *ngIf="addDropdown">
            <div class="choice flex">
              <input type="checkbox" (change)="facebook = !facebook" [checked]="facebook" />
              <div>Facebook</div>
            </div>
            <div class="choice flex">
              <input type="checkbox" (change)="twitter = !twitter" [checked]="twitter" />
              <div>Twitter</div>
            </div>
            <div class="choice flex">
              <input type="checkbox" (change)="linkedin = !linkedin" [checked]="linkedin" />
              <div>Linkedin</div>
            </div>
            <div class="choice flex">
              <input type="checkbox" (change)="instagram = !instagram" [checked]="instagram" />
              <div>Instagram</div>
            </div>
            <div class="choice flex">
              <input type="checkbox" (change)="youtube = !youtube" [checked]="youtube" />
              <div>Youtube</div>
            </div>
            <div class="choice flex">
              <input type="checkbox" (change)="wordpress = !wordpress" [checked]="wordpress" />
              <div>WordPress</div>
            </div>
            <div class="button flex-center" (click)="addDropdown = false">
              {{ language.done }}
            </div>
          </div>
        </div>
      </div>
      <div class="social-handles social-wrapper flex">
        <div class="social-button flex" *ngIf="facebook">
          <div class="fb-icon"></div>
          <input type="text" placeholder="facebook" [(ngModel)]="registerResponseModel.mediaUserModel.facebookLink"
            formControlName="facebook" />
        </div>
        <div class="social-button flex" *ngIf="twitter">
          <div class="tw-icon"></div>
          <input type="text" placeholder="twitter" [(ngModel)]="registerResponseModel.mediaUserModel.twitterLink"
            formControlName="twitter" />
        </div>
        <div class="social-button flex" *ngIf="instagram">
          <div class="ig-icon"></div>
          <input type="text" placeholder="instagram" [(ngModel)]="registerResponseModel.mediaUserModel.instagramLink"
            formControlName="instagram" />
        </div>
        <div class="social-button flex" *ngIf="linkedin">
          <div class="linkedin-icon"></div>
          <input type="text" placeholder="linkedin" [(ngModel)]="registerResponseModel.mediaUserModel.linkedInLink"
            formControlName="linkedin" />
        </div>
        <div class="social-button flex" *ngIf="youtube">
          <div class="youtube-icon"></div>
          <input type="text" placeholder="youtube" [(ngModel)]="registerResponseModel.mediaUserModel.youTubeLink"
            formControlName="youtube" />
        </div>
        <div class="social-button flex" *ngIf="wordpress">
          <div class="wordpress-icon"></div>
          <input type="text" placeholder="wordpress" [(ngModel)]="registerResponseModel.mediaUserModel.wordPressLink"
            formControlName="wordpress" />
        </div>
      </div>

      <div class="checkbox-wrapper">
        <div class="flex">
          <input type="checkbox" [(ngModel)]="
              registerResponseModel?.mediaUserModel.receiveNewsletter
            " (click)="newsletterCheck()" [checked]="registerResponseModel.mediaUserModel.receiveNewsletter"
            formControlName="newsletter" />
          <span>{{ language.iWantNewsletter }}</span>
        </div>
      </div>
      <div class="register-buttons flex-center">
        <button type="submit" class="submit flex-center" appStyle [defaultColor]="styles.style.mainIdentifyingColor">
          Update
        </button>
      </div>
    </form>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>