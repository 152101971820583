<div class="social-share-wrapper" [ngClass]="[
    isMediaShareBig
      ? 'mediaShareBig'
      : '' || isMediaShareVertical
      ? 'isMediaShareVertical'
      : '' || big
      ? 'share-button-wrapper'
      : '' || vertical
      ? 'vertical'
      : ''
  ]" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="open-share-button share-button" [ngClass]="[whiteShareStyle ? 'white-share' : '']"
    (click)="openShare($event)">
    <i class="fal fa-share-alt"></i>
    {{ vertical || noTitle || (!horizontal && open) ? "" : language.share }}
    <span *ngIf="!hideShareNumber">{{ shared }}</span>
  </div>

  <div class="social-buttons" [ngClass]="{
      'news-detail-v2': !webNewsDetailsResponse?.smallHeroImage,
      vertical: vertical,
      horizontal: horizontal,
      open: open
    }">
    <ng-container *ngFor="let social of socials">
      <ng-container *ngIf="social.toLowerCase().indexOf('facebook') > -1">
        <div class="share-button round generic-button" (click)="clickSharedNews('facebook'); $event.stopPropagation()">
          <i class="fab fa-facebook"></i>
        </div>
      </ng-container>

      <ng-container *ngIf="social.toLowerCase().indexOf('twitter') > -1">
        <div class="share-button round generic-button" (click)="clickSharedNews('twitter'); $event.stopPropagation()">
          <i class="fab fa-twitter"></i>
        </div>
      </ng-container>

      <ng-container *ngIf="social.toLowerCase().indexOf('linkedin') > -1">
        <div class="share-button round generic-button" (click)="clickSharedNews('linkedin'); $event.stopPropagation()">
          <i class="fab fa-linkedin"></i>
        </div>
      </ng-container>

      <ng-container *ngIf="social.toLowerCase().indexOf('mail') > -1">
        <a class="share-button round generic-button" target="_blank" [href]="
            'mailto:?subject=' +
            title +
            '&amp;body=' +
            description +
            '%0D%0A%0D%0A%0D%0A' +
            'Link to story: ' +
            urlStoryForEmail
          " (click)="clickSharedNews('mail'); $event.stopPropagation()">
          <i class="fas fa-envelope"></i>
        </a>
      </ng-container>
      <ng-container *ngIf="social.toLowerCase().indexOf('embed') > -1">
        <div class="share-button round generic-button" (click)="openEmbeddedPopup(); $event.stopPropagation()">
          <i class="fas fa-code"></i>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-embedded-share *ngIf="embeddedPopupShow" [title]="title" [mediaId]="mediaId" (embeddClose)="closeEmbeddedPopup()"
  [isPublished]="isPublished"></app-embedded-share>