<section class="login flex">
  <div class="register-wrapper">
    <div class="headline" [ngStyle]="{'color':  styles.style?.textH2Color}">{{language.forgotPassword}}</div>
    <p *ngIf="notSend">Please enter your email and we will send you a link to reset your password.</p>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="sendEmail($event,data)">
      <div class="form-element flex">
        <input name='emailforpassword' [(ngModel)]="data.email" (keyup)="checkEmail = false" type="email"
          class="custom-input" placeholder="Insert your Email" formControlName="emailforpassword">
        <div class="text-danger"
          *ngIf="valForm.controls['emailforpassword'].hasError('required') && (valForm.controls['emailforpassword'].dirty || valForm.controls['emailforpassword'].touched)">
          {{ language.emailNameRequired }}
        </div>
        <div class="text-danger"
          *ngIf="valForm.controls['emailforpassword'].hasError('email') && (valForm.controls['emailforpassword'].dirty || valForm.controls['emailforpassword'].touched)">
          {{ language.invalidEmail }}
        </div>
        <div class="text-danger"
          *ngIf="checkEmail && !valForm.controls['emailforpassword'].hasError('email') && !valForm.controls['emailforpassword'].hasError('required')">
          There is no user registered with this email.
        </div>
      </div>
      <div class="register-buttons flex-center">
        <button type="submit" class="submit flex-center" [ngClass]="[maiLSent?'disabled':'']" [disabled]="maiLSent"
          [ngStyle]="{'background':  styles.style?.mainIdentifyingColor}">{{language.submit}}</button>
      </div>
    </form>
    <p *ngIf='!notSend'>Recovery instructions are sent. Please check your email.</p>
  </div>
</section>