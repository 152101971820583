import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordCheckService {

  constructor() { }

  hasPasswordHardening() {
    return localStorage.getItem('hasPasswordHardening') === 'true';
  }

  passwordCheckSpecial(password) {
    if (!password) return false;
    const pecialCharacters = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    for (let i = 0; i < password.length; i++) {
      if (pecialCharacters.indexOf(password[i]) > -1) {
        return true;
      }
    }
    return false;
  }

  passwordCheckCase(password) {
    if (!password) return false;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    if (upperCase.test(password) && lowerCase.test(password)) {
      return true;
    }
    return false;
  }

  passwordCheckLength(password) {
    if (!password) return false;
    if (password.length >= 8) {
      return true;
    }
    return false;
  }

  passwordCheckNumber(password) {
    if (!password) return false;
    const numbers = /[0-9]/;
    if (numbers.test(password)) {
      return true;
    }
    return false;
  }

  checkAll(password) {
    return this.passwordCheckSpecial(password)
      && this.passwordCheckCase(password)
      && this.passwordCheckLength(password)
      && this.passwordCheckNumber(password);
  }
}
