<div class="popup-background">
  <div class="popup-overlay"></div>
  <div class="popup">
    <div class="close-icon" (click)="close()"></div>
    <section class="login flex">
      <div class="gray-background-login"></div>
      <div class="register-wrapper">
        <div class="subHeader">{{ language.downloadMediaLogIn }}</div>
        <div class="headline">{{language.signIn}}</div>
        <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="submitLoginForm($event)">
          <div class="form-element flex">
            <label for="email">{{language.email}}</label>
            <input type="email" id="email" [(ngModel)]="loginModel.email" name="email"
              placeholder="{{language.enterEmail}}" formControlName="username">
            <span class="text-danger"
              *ngIf="valForm.controls['username'].hasError('required') && (valForm.controls['username'].dirty || valForm.controls['username'].touched)">
              {{ language.emailNameRequired }}
            </span>
            <span class="text-danger"
              *ngIf="valForm.controls['username'].hasError('email') && (valForm.controls['username'].dirty || valForm.controls['username'].touched)">Must
              be a valid email address</span>
          </div>
          <div class="form-element flex">
            <label for="password">{{language.password}}</label>
            <input type="password" name="password" [(ngModel)]="loginModel.password" id="password"
              placeholder="{{language.enterPassword}}" formControlName="password">
            <span class="text-danger"
              *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
              Password is required
            </span>
          </div>
          <div class="reset-pass" (click)="forgotPassword()">{{language.forgotPassword}}</div>
          <div class="register-buttons flex-center">
            <button type="submit" class="submit flex-center"
              [ngStyle]="{'background':  styles.style?.mainIdentifyingColor}">{{language.submit}}
            </button>
            <ng-container *ngIf="layoutResponseModel.loginWithFacebook">
              <div>{{ language.or }}</div>
              <div class="facebook flex-center">
                <i class="fab fa-facebook"></i>
                {{language.signInFb}}
              </div>
            </ng-container>
            <ng-container *ngIf="layoutResponseModel.loginWithTwitter">

              <div>{{ language.or }}</div>
              <div class="twitter flex-center">{{language.signInTw}}</div>
            </ng-container>
          </div>
        </form>
        <div *ngIf="!layoutResponseModel?.disableRegistration" class="headline no-margin"><span
            (click)="registerPage()">or {{language.register}}</span></div>

      </div>
    </section>
  </div>

</div>
<app-loader *ngIf="loader"></app-loader>