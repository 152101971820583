import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { Consent } from "../../models/consent/consent.model";

@Injectable({
  providedIn: "root",
})
export class UserCentricsService {
  private consents: Consent = new Consent();
  private consentSubject: Subject<Consent> = new Subject();
  private ga4Id = "G-RQWSC8YPM4";

  constructor() {}

  setConsents(consentsFromEvent: any) {
    const newConsents: Consent = { ...this.consents };
    newConsents.googleAnalytics = consentsFromEvent["Google Analytics"];
    newConsents.googleTagManager = consentsFromEvent["Google Tag Manager"];
    newConsents.facebookSocialPlugins =
      consentsFromEvent["Facebook Social Plugins"];
    newConsents.UsercentricsConsentManagementPlatform =
      consentsFromEvent["Usercentrics Consent Management Platform"];
    this.consents = newConsents;
    this.consentSubject.next(this.consents);
    if (this.isAnalyticsConsented()) this.initializeAnalytics();
    else this.destroyAnalytics();
    if (this.isFBConsented()) this.initializeFB();
    else this.destroyFB();
  }

  isAnalyticsConsented() {
    return this.consents.googleAnalytics && this.consents.googleTagManager;
  }

  updateGA4Id(newId) {
    if (newId != this.ga4Id) {
      this.ga4Id = newId;
      this.destroyAnalytics();
      this.initializeAnalytics();
    }
  }

  initializeAnalytics() {
    if (
      window &&
      document &&
      !document.getElementById("gtag1") &&
      !document.getElementById("gtag2")
    ) {
      const script1 = document.createElement("script");
      script1.id = "gtag1";
      script1.src = "https://www.googletagmanager.com/gtag/js?id=" + this.ga4Id;
      script1.async = true;

      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.id = "gtag2";
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${this.ga4Id}');
      `;

      document.head.appendChild(script2);
    }
  }

  destroyAnalytics() {
    if (
      document &&
      document.getElementById("gtag1") &&
      document.getElementById("gtag2")
    ) {
      var parentHead = document.head;
      var gtag1 = document.getElementById("gtag1");
      var gtag2 = document.getElementById("gtag2");
      parentHead.removeChild(gtag1);
      parentHead.removeChild(gtag2);
    }
  }

  isFBConsented() {
    return this.consents.facebookSocialPlugins;
  }

  initializeFB() {
    if (window && document && !document.getElementById("fbScript")) {
      // Create the script element
      var script = document.createElement("script");
      script.id = "fbScript";
      script.type = "text/javascript";
      script.src = "https://connect.facebook.net/en_US/sdk.js";

      // Append the script element to the document head
      document.head.appendChild(script);
    }
  }

  destroyFB() {
    if (document && document.getElementById("fbScript")) {
      var parentHead = document.head;
      var fbScript = document.getElementById("fbScript");
      parentHead.removeChild(fbScript);
    }
  }
}
