import { Injectable } from '@angular/core';
import { PageStyle } from '../../models/style/style.model';

@Injectable()
export class FontsAndColorsService {

  pageStyleInfo: PageStyle;
  constructor() {
  }

  setPageStyleInfo(response: PageStyle) {
    this.pageStyleInfo = response;
  }

  getPageStyleInfo(): PageStyle {
    if (this.pageStyleInfo) {
      return this.pageStyleInfo;
    }

    return new PageStyle();
  }

}
