<section class="contacts">
  <div class="title-container">
    <h1>Contact Us</h1>
    <!-- <h2>Media contacts</h2> -->
  </div>
  <div class="contacts-wrapper">
    <div class="text-boxes">
      <div>
        <h2>
          Media
        </h2>
        <p>
          If you’re a journalist and would like to speak to one of the team, please
          <b>email us using the contact details below</b>.
          Our office hours are 09.00 to 17.00 Monday to Friday.
        </p>
        <p>
          <b>Outside of office hours only</b>, please call +44 (0) 1582 525252 for instructions to speak to the duty
          press officer.
        </p>
        <p>
          <b>
            <!-- Please note, we can only respond to media requests using these contacts.  -->
            We will be unable to answer any queries not from journalists
          </b>
          so please visit the <a href="https://www.easyjet.com/en/help/contact/contact-us" target="_blank">support</a>
          section where you can find many of the most frequently asked questions and alternative contact details.
        </p>
      </div>
      <div>
        <h2>
          Corporate and <br />Government Relations
        </h2>
        <p>
          If you’d like to talk to us about stakeholder relations, Government, political or policy issues please contact
          our
          public affairs team at: <a href="mailto:public.affairs@easyjet.com">public.affairs@easyjet.com</a>.
        </p>
      </div>
    </div>
    <div class="contacts-content">
      <div class="contact" *ngFor="let contact of contacts.ourTeam">
        <!-- <img [src]="contact.thumbUrl" alt="Contact image" /> -->
        <img *ngIf="contact.thumbUrl !== '1'" [src]="contact.thumbUrl" alt="Contact image" />
        <div *ngIf="contact.thumbUrl === '1'" class="imgPlaceholder"></div>
        <h1>{{contact.name}}</h1>
        <h2>{{contact.email}}</h2>
        <!-- <p>{{contact.description}}</p> -->
        <p>{{contact.description}}</p>
      </div>
    </div>
    <div class="table">
      <h2>Please find a schedule of who to contact for any out-of-hours media enquiries.</h2>
      <div>
        <table>
          <tr *ngFor="let item of contacts.rotary | slice: 0:(contacts?.rotary?.length / 2); " [ngClass]="item.day === 'Friday' || item.day === 'Saturday' || item.day === 'Sunday' ? 'colored' 
          : item.day === 'Thursday' ? 'border-bottom-colored' : ''">
            <th>{{item.day}}</th>
            <td>{{item.date}}</td>
            <td>{{item.name}}</td>
          </tr>
        </table>
        <table>
          <tr *ngFor="let item of contacts.rotary | slice: (contacts?.rotary?.length / 2):(contacts?.rotary?.length)"
            [ngClass]="item.day === 'Friday' || item.day === 'Saturday' || item.day === 'Sunday' ? 'colored' 
          : item.day === 'Thursday' ? 'border-bottom-colored' : ''">
            <th>{{item.day}}</th>
            <td>{{item.date}}</td>
            <td>{{item.name}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!-- <app-loader *ngIf="loader"></app-loader> -->
</section>