<div *ngIf="hasShareButton()" class="share-button" [shareButton]="getNetworkType()"
  (click)="share();$event.stopPropagation()" (mouseover)="hovered=true" (mouseout)="hovered=false">
  <div class="{{getNetworkTypeClass()}}"></div>
</div>
<div *ngIf="!hasShareButton()" class="share-button" (click)="share();$event.stopPropagation()"
  (mouseover)="hovered=true" (mouseout)="hovered=false">
  <div class="{{getNetworkTypeClass()}}"></div>
</div>
<app-embedded-share *ngIf="embeddedPopupShow" [popupType]="'big'" [title]="title" [mediaId]="mediaId"
  (embeddClose)="closeEmbeddedPopup()">
</app-embedded-share>