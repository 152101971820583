import { BaseLookupModel, CustomCategoryLookupModel } from '../search/search-model';
import { EnumModel } from '../../register/register-model';

export class ProfileModel {
  public mediaUserModel: UserManageModel;
  public customCatogories: CustomCategoryLookupModel[];
  public languages: BaseLookupModel[];
  public userGroups: EnumModel[];
  public adminModel: AdminModel;

  constructor() {
    this.mediaUserModel = new UserManageModel();
    this.adminModel = new AdminModel();
  }
}


export class UserManageModel {
  public id: number;
  public fullName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public oldPassword: string;
  public confirmPassword: string;
  public telephoneNumber: string;
  public companyName: string;
  public mediaUserTypes: string[];
  public facebookToken: string;
  public facebookLink: string;
  public twitterLink: string;
  public linkedInLink: string;
  public instagramLink: string;
  public youTubeLink: string;
  public wordPressLink: string;
  public receiveNewsletter: boolean;
  public customCategoryIds: number[];
  public languageIds: number[];
  public isPasswordSet: boolean;
}

export class AdminModel {
  public id: number;
  public fullName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public telephone: string;
  public telephoneNumber: string;
  public isPasswordSet: boolean;
}
