export class MyFilesModel {
    public storyMediaIds: number[];
    public publishedMediaIds: number[];
    constructor(storyMediaIds: number[], publishedMediaIds: number[]) {
        this.storyMediaIds = storyMediaIds;
        this.publishedMediaIds = publishedMediaIds;
    }

    public isInMyFiles(id: number, isPublished: boolean): boolean {

        if (isPublished) {
            return this.publishedMediaIds.indexOf(id) > -1;
        } else {
            return this.storyMediaIds.indexOf(id) > -1;
        }

    }

    get length(): number {
        return this.storyMediaIds.length + this.publishedMediaIds.length;
    }
}