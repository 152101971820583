import { UrlsModel } from "../../models/links/urls.model";

export class AboutUsModel {
  public aboutUsH1Text: string;
  public aboutUsH2Text: string;
  public aboutUsH3Text: string;
  public aboutUsText: string;
  public aboutUsFinalText: string;
  public aboutUsQuoteText: string;
  public aboutUsSignature: string;
  public aboutUsBeforeStoreButtonsText: string;
  public aboutUsAfterStoreButtonsText: string;
  public aboutUsHeroImageUrl: string;
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public aboutUsLinks: UrlsModel[];
  public ourTeam: TeamModel[];

  constructor() {
    this.aboutUsH1Text = '';
    this.aboutUsH2Text = '';
    this.aboutUsH3Text = '';
    this.aboutUsQuoteText = '';
    this.aboutUsSignature = '';
    this.aboutUsText = '';
    this.aboutUsHeroImageUrl = '';
    this.aboutUsAfterStoreButtonsText = '';
    this.aboutUsBeforeStoreButtonsText = '';
  }
}
export class TeamModel {
  public name: string;
  public position: string;
  public thumbUrl: string;
}
