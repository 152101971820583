import { Component, OnInit } from '@angular/core';
import { TokenManagerService } from "../shared/services/token-manager.service";
import { LoginModel, NewPasswordModel } from "../login/login-model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Language } from "../models/language/language.model";
import { LanguageService } from "../shared/services/language.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { StorageService } from "../shared/services/storage.service";
import { PageStyle } from "../models/style/style.model";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { ToastrService } from 'ngx-toastr';
import { ToastrConfig } from '../shared/helpers/toastr-config';

@Component({
  selector: 'app-register-imported',
  templateUrl: './register-imported.component.html',
  styleUrls: ['./register-imported.component.less']
})
export class RegisterImportedComponent implements OnInit {
  valForm: FormGroup;
  loader = false;
  data = new NewPasswordModel();
  patternPassword = /^\S*$/;
  confirmError: boolean;
  language: Language;

  styles = new PageStyle();

  constructor(private fb: FormBuilder, private languageService: LanguageService, private toastr: ToastrService, private activatedRoute: ActivatedRoute, private authLoginService: TokenManagerService, private router: Router, private sService: StorageService, public fontsAndColorsService: FontsAndColorsService) {
    this.valForm = this.fb.group({
      'new-password': [null, Validators.compose([Validators.required, Validators.pattern(this.patternPassword)])],
      'confirmpassword': [null, Validators.required]
    });
    this.language = languageService.getLanguage();
    this.styles = fontsAndColorsService.getPageStyleInfo();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const resetCode = params['code'];
      this.data.resetCode = resetCode;

    });

    // this.authLoginService.checkAuthentication(this.data.resetCode)
    //   .subscribe(
    //     (response) => {
    //
    //     }, (error) => {
    //       const toast: Toast = {
    //         type: 'error',
    //         title: 'Error',
    //         body: 'Reset link has already been used.',
    //         showCloseButton: true,
    //         timeout: 5000,
    //         closeHtml: '<div class="toaster-close-icon"></div>'
    //       };
    //
    //       this.toasterService.pop(toast);
    //       this.router.navigate(['./login']);
    //     }
    //   );
  }

  checkPassword() {
    if (this.valForm.controls['new-password'].value === this.valForm.controls['confirmpassword'].value) {
      this.confirmError = false;
    }
  }

  reset(e, data) {
    e.stopPropagation();

    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {

      this.authLoginService.registerImported(data)
        .subscribe(
          (response: any) => {
            //
            this.toastr.success('User registered!', 'Well done!', ToastrConfig.success);
            // this.router.navigate(['/login']);
          },
          (error) => {
            const body = JSON.parse(error._body);
            this.toastr.error(body.message, 'Error', ToastrConfig.error);
            //
          }
        );
    }
  }
}
