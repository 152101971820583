<section class="login flex">
  <div class="register-wrapper">
    <h1 class="headline">Reset password</h1>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="reset($event,data)">
      <div class="form-element flex">
        <label for="new-password">{{language.password}}</label>
        <input type="password" id="new-password" name="new-password" placeholder="Enter your new password"
          [(ngModel)]="data.newPassword" formControlName="new-password" (focus)="onPwFocus()" (blur)="onPwBlur()">
        <span class="text-danger"
          *ngIf="valForm.controls['new-password'].hasError('required') && (valForm.controls['new-password'].dirty || valForm.controls['new-password'].touched)">
          New password is required
        </span>
        <span class="text-danger"
          *ngIf="valForm.controls['new-password'].hasError('pattern') && (valForm.controls['new-password'].dirty || valForm.controls['new-password'].touched)">
          {{ language.passwordNoSpaces }}</span>
        <div class="password-checker" *ngIf="checkPasswordService.hasPasswordHardening() && isPwFocus">
          <div class="password-checker-item">
            <div class="pw-error" *ngIf="!checkPasswordService.passwordCheckNumber(data.newPassword)">X
            </div>
            <img src="/assets/icons/pw-tick.svg" *ngIf="checkPasswordService.passwordCheckNumber(data.newPassword)">
            <span [ngClass]="checkPasswordService.passwordCheckNumber(data.newPassword) ? 'pw-green' : 'pw-red' ">Number
              (0-9)</span>
          </div>
          <div class="password-checker-item">
            <div class="pw-error" *ngIf="!checkPasswordService.passwordCheckSpecial(data.newPassword)">X
            </div>
            <img src="/assets/icons/pw-tick.svg" *ngIf="checkPasswordService.passwordCheckSpecial(data.newPassword)">
            <span
              [ngClass]="checkPasswordService.passwordCheckSpecial(data.newPassword) ? 'pw-green' : 'pw-red' ">Special
              Character</span>
          </div>
          <div class="password-checker-item">
            <div class="pw-error" *ngIf="!checkPasswordService.passwordCheckLength(data.newPassword)">X
            </div>
            <img src="/assets/icons/pw-tick.svg" *ngIf="checkPasswordService.passwordCheckLength(data.newPassword)">
            <span
              [ngClass]="checkPasswordService.passwordCheckLength(data.newPassword) ? 'pw-green' : 'pw-red' ">Atleast
              8 Character</span>
          </div>
          <div class="password-checker-item">
            <div class="pw-error" *ngIf="!checkPasswordService.passwordCheckCase(data.newPassword)">
              X</div>
            <img src="/assets/icons/pw-tick.svg" *ngIf="checkPasswordService.passwordCheckCase(data.newPassword)">
            <span
              [ngClass]="checkPasswordService.passwordCheckCase(data.newPassword) ? 'pw-green' : 'pw-red' ">Lowercase
              & Uppercase</span>
          </div>
        </div>
      </div>
      <div class="form-element flex">
        <label for="confirmPassword">{{language.confirmPassword}}</label>
        <input type="password" name="confirmPassword" id="confirmPassword" [placeholder]="language.confirmPassword"
          [ngClass]="[confirmError ? 'ng-invalid ng-touched' : '']" (keyup)="checkPassword()"
          [(ngModel)]="data.confirmPassword" formControlName="confirmpassword">
        <span class="text-danger"
          *ngIf="valForm.controls['confirmpassword'].hasError('required') && (valForm.controls['confirmpassword'].dirty || valForm.controls['confirmpassword'].touched)">
          {{ language.confirmPasswordRequired }}
        </span>
        <span class="text-danger"
          *ngIf="valForm.controls['confirmpassword'].value != valForm.controls['new-password'].value && !valForm.controls['confirmpassword'].hasError('required') && valForm.controls['new-password'].value != '' ">Passwords
          do not match.</span>
      </div>
      <div class="register-buttons flex-center">
        <button type="submit" class="submit flex-center">{{language.submit}}</button>
      </div>
    </form>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>