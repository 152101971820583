import {BaseLookupModel, CustomCategoryLookupModel} from '../header/search/search-model';

export class RegisterModel {
  public mediaUserModel: UserManageModel;
  public userGroups: EnumModel[];
  public customCatogories: CustomCategoryLookupModel[];
  public languages: BaseLookupModel[];
  public registrationInfoModel: RegistrationInfoModel;

  constructor() {
    this.mediaUserModel = new UserManageModel();
  }
}

export class EnumModel{
  public id: string;
  public name: string;

  //added new properties
  public checked: boolean;
}


export class UserManageModel {
  public id: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public telephoneNumber: number;
  public companyName: string;
  public facebookToken: string;
  public facebookLink: string;
  public twitterAuthorizationVerifier: string;
  public twitterAuthorizationId: string;
  public twitterLink: string;
  public linkedInLink: string;
  public mediaUserTypes: string[];
  public instagramLink: string;
  public youTubeLink: string;
  public wordPressLink: string;
  public receiveNewsletter: boolean;
  public termsAndConditions: boolean;
  public customCategoryIds: number[];
  public languageIds: number[];

  constructor() {
    this.id = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = '';
    this.telephoneNumber;
    this.companyName = '';
    this.facebookToken = '';
    this.facebookLink = '';
    this.twitterLink = '';
    this.linkedInLink = '';
    this.instagramLink = '';
    this.youTubeLink = '';
    this.wordPressLink = '';
    this.receiveNewsletter = false;
    this.customCategoryIds = [];
    this.languageIds = [];
  }
}

export class RegistrationInfoModel {
  public registerNotificationText: string;
  public registerTermsQuestion: string;
  public registerNotificationQuestion: string;

  constructor() {
    this.registerNotificationText = '';
    this.registerNotificationQuestion = '';
  }
}
