import {Component, OnInit} from '@angular/core';
import {McService} from '../../shared/services/mc-service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-redirect-old',
  template: ''
})
export class RedirectOldComponent implements OnInit {

    id: number;
    type: string;
    url: string;

    constructor(private router: Router, private route: ActivatedRoute){
        this.route.params.subscribe();
    }

    // https://digitalnewsagency.com/media/254056/share
    // {path: 'media/:id/share', component: RedirectOldComponent},

    // https://digitalnewsagency.com//users/49390/reset_password/f30373a60686b05d10b2c594d973666f4bf9f740
    // {path: 'users/:id/reset_password/:hash', component: RedirectOldComponent},

    ngOnInit() {
        this.url = 'http://dna3.digitalnewsagency.com/';
        this.type = this.route.snapshot.url[0].toString();

        for (let a of this.route.snapshot.url) {
            this.url += a + '/';
        }

        //

        document.location.href = this.url;

        // //

        // if (this.type == 'users') {
        //     document.location.href = this.url;
        // }
        // else {

        //     this.id = parseInt(this.route.snapshot.paramMap.get('id'));

        //     //
        //     //


        //     this.router.navigate(['/story/' + this.id + '/title']);
        // }
    }

}
